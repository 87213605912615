import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';
import "./details.css";

const Details = ({ scratch, python, roblox, fullstack, setDetails, detailsName, detailsPath, setDetailsPath }) => {
    return (
        <div className='overlay'>
            <div className='details-container'>

                <div className='details-form'>

                    <div className='details-icon-container'>
                        <FaRegWindowClose style={{ cursor: "pointer" }} onClick={() => setDetails(false)} size={26} />
                    </div>

                    <h1>{detailsName}'s Progress</h1>


                    <div className='label-container'>
                        <h3 onClick={() => setDetailsPath("scratch")} >Scratch</h3>

                        <h3 onClick={() => setDetailsPath("python")} >Python</h3>

                        <h3 onClick={() => setDetailsPath("roblox")} >Roblox</h3>

                        <h3 onClick={() => setDetailsPath("fullstack")} >Fullstack</h3>

                    </div>

                    <div className='project-tag-container'>
                        {
                            detailsPath == "scratch" &&
                            scratch?.map((project) => {
                                return (
                                    <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                        <h3>{project[0]}</h3>
                                    </div>
                                )
                            })
                        }

                        {
                            detailsPath == "python" &&
                            python?.map((project) => {
                                return (
                                    <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                        <h3>{project[0]}</h3>
                                    </div>
                                )
                            })
                        }

                        {
                            detailsPath == "roblox" &&
                            roblox?.map((project) => {
                                return (
                                    <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                        <h3>{project[0]}</h3>
                                    </div>
                                )
                            })
                        }

                        {
                            detailsPath == "fullstack" &&
                            fullstack?.map((project) => {
                                return (
                                    <div className={project[1] == true ? 'project-tag-true' : 'project-tag-false'}>
                                        <h3>{project[0]}</h3>
                                    </div>
                                )
                            })
                        }
                    </div>



                </div>

            </div>
        </div>
    )
}

export default Details