import React, { useEffect, useState } from 'react';
import Video from '../../components/video/video';
import CodeCard from '../../components/codecard/codecard';
import Indent from '../../components/indent/indent';
import api from "../../api/api";
import Loader from '../../components/loader/loader';
import CopyBox from '../../components/copybox/copybox';

const GemDispenserScript = () => {
  return (
    <CodeCard title={"Gem Dispenser Script"}>
      <span class="keyword">function</span> <span class="identifier">grabGem</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">stone</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">.</span><span class="property">Folder</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">[</span><span class="number">1</span><span class="punctuation">]</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="keyword">not</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Backpack</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">stone</span><span class="operator">.</span><span class="property">Name</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="keyword">not</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">stone</span><span class="operator">.</span><span class="property">Name</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">clone</span> <span class="operator">=</span> <span class="identifier">stone</span><span class="operator">:</span><span class="function">Clone</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="identifier">clone</span><span class="operator">.</span><span class="property">part</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Handle"</span><br />
      <Indent /><Indent />
      <span class="identifier">clone</span><span class="operator">.</span><span class="property">Handle</span><span class="operator">.</span><span class="property">Anchored</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><Indent />
      <span class="identifier">clone</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Backpack</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">.</span><span class="property">Hitbox</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">grabGem</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const ForceFieldScript = () => {
  return (
    <CodeCard title={"Force Field Script"}>
      <span class="keyword">local</span> <span class="identifier">block</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onTouched</span><span class="punctuation">(</span><span class="identifier">hit</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">hit</span><span class="operator">.</span><span class="property">Parent</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">tool</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Backpack</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Green Stone"</span><span class="punctuation">)</span> <span class="operator">or</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Green Stone"</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">tool</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><Indent />
      <span class="keyword">else</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onTouched</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const JumpSpringScript = () => {
  return (
    <CodeCard title={"Jump Spring Script"}>
      <span class="keyword">local</span> <span class="identifier">spring</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">equippedSpring</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">spring</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">JumpPower</span> <span class="operator">=</span> <span class="number">200</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">spring</span><span class="operator">.</span><span class="property">Equipped</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">equippedSpring</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">unequippedSpring</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">JumpPower</span> <span class="operator">=</span> <span class="number">50</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">spring</span><span class="operator">.</span><span class="property">Unequipped</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">unequippedSpring</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const SpeedStaffScript = () => {
  return (
    <CodeCard title={"Speed Staff Script"}>
      <span class="keyword">local</span> <span class="identifier">staff</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">equippedStaff</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">staff</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="number">50</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">staff</span><span class="operator">.</span><span class="property">Equipped</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">equippedStaff</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">unequippedStaff</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="number">16</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">staff</span><span class="operator">.</span><span class="property">Unequipped</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">unequippedStaff</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const BorderScript = () => {
  return (
    <CodeCard title={"Border Script"}>
      <span class="keyword">local</span> <span class="identifier">part</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">requiredStones</span> <span class="operator">=</span> <span class="punctuation">&#123;</span>
      <span class="string">"Green Stone"</span>, <span class="string">"Red Stone"</span>, <span class="string">"Blue Stone"</span>, <span class="string">"Yellow Stone"</span>, <span class="string">"Purple Stone"</span>
      <span class="punctuation">&#125;</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">hasAllRequiredStones</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">backpack</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Backpack"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">backpack</span> <span class="keyword">then</span> <span class="keyword">return</span> <span class="boolean">false</span> <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">stone</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">requiredStones</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">backpack</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">stone</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">return</span> <span class="boolean">false</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">return</span> <span class="boolean">true</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onPartTouched</span><span class="punctuation">(</span><span class="identifier">other</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">other</span><span class="operator">.</span><span class="property">Parent</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">hasAllRequiredStones</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">part</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><Indent /><Indent />
      <span class="builtin">wait</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">part</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">part</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onPartTouched</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const TimerScript = () => {
  return (
    <CodeCard title={"Timer Script"}>
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">timerEvent</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"RemoteEvent"</span><span class="punctuation">)</span><br />
      <span class="identifier">timerEvent</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"TimerEvent"</span><br />
      <span class="identifier">timerEvent</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">requiredTools</span> <span class="operator">=</span> <span class="punctuation">&#123;</span><span class="string">"Green Stone"</span>, <span class="string">"Red Stone"</span>, <span class="string">"Blue Stone"</span>, <span class="string">"Yellow Stone"</span>, <span class="string">"Purple Stone"</span><span class="punctuation">&#125;</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">hasAllRequiredTools</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">backpack</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Backpack"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">backpack</span> <span class="keyword">then</span> <span class="keyword">return</span> <span class="boolean">false</span> <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">toolName</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">requiredTools</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">backpack</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">toolName</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">return</span> <span class="boolean">false</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">return</span> <span class="boolean">true</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">startTimer</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">timerValue</span> <span class="operator">=</span> <span class="number">10</span> <span class="operator">*</span> <span class="number">60</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">timerRunning</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">function</span> <span class="identifier">resetTimer</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="identifier">timerValue</span> <span class="operator">=</span> <span class="number">10</span> <span class="operator">*</span> <span class="number">60</span><br />
      <Indent /><Indent />
      <span class="identifier">timerRunning</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">function</span> <span class="identifier">stopTimer</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="identifier">timerRunning</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">resetTimer</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="builtin">wait</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">timerRunning</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">timerValue</span> <span class="operator">=</span> <span class="identifier">timerValue</span> <span class="operator">-</span> <span class="number">1</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">timerEvent</span><span class="operator">:</span><span class="function">FireClient</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">,</span> <span class="identifier">timerValue</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">timerValue</span> <span class="operator">&lt;=</span> <span class="number">0</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">hasAllRequiredTools</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">stopTimer</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">player</span><span class="operator">:</span><span class="function">LoadCharacter</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="builtin">wait</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">resetTimer</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="keyword">else</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">timerEvent</span><span class="operator">:</span><span class="function">FireClient</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">,</span> <span class="string">"You have all of the gems!"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">Players</span><span class="operator">.</span><span class="property">PlayerAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="identifier">startTimer</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="builtin">wait</span><span class="punctuation">(</span><span class="number">60</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><span class="punctuation">)</span><br />
    </CodeCard>
  );
};

const TextLabelScript = () => {
  return (
    <CodeCard title={"Text Label Script"}>
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">timerEvent</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"TimerEvent"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">timerLabel</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">updateTime</span><span class="punctuation">(</span><span class="identifier">data</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="builtin">type</span><span class="punctuation">(</span><span class="identifier">data</span><span class="punctuation">)</span> <span class="operator">==</span> <span class="string">"number"</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">timerLabel</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="identifier">data</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">elseif</span> <span class="builtin">type</span><span class="punctuation">(</span><span class="identifier">data</span><span class="punctuation">)</span> <span class="operator">==</span> <span class="string">"string"</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">timerLabel</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="identifier">data</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">timerEvent</span><span class="operator">.</span><span class="property">OnClientEvent</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">updateTime</span><span class="punctuation">)</span><br />
    </CodeCard>
  );
};

const GemHunter = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectVideo, setProjectVideo] = useState('');
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchVideoUrls = async (fileNames) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/videos`, { fileNames });
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching video URLs:', error.response?.data || error.message);
      return [];
    }
  };

  useEffect(() => {
    const fileNames = [
      'gemhunter/GEMHUNTER1.mp4', 
      'gemhunter/GEMHUNTER2.mp4', 
      'gemhunter/GEMHUNTER3.mp4',
      'gemhunter/GEMHUNTER4.mp4',
      'gemhunter/GEMHUNTER5.mp4',
      'gemhunter/GEMHUNTER6.mp4',
      'gemhunter/GEMHUNTER7.mp4',
      'gemhunter/GEMHUNTER8.mp4',
      'gemhunter/GEMHUNTER9.mp4'
    ];

    fetchVideoUrls(fileNames).then((urls) => {
      if (urls && Array.isArray(urls)) {
        setVideoUrls(urls);

        // Dynamically create the tutorials array
        const dynamicTutorials = [
          ["Starting Gem Hunter", urls[0]?.url],
          ["Dune Buggy Parking & Glowing Rocks!", urls[1]?.url, <CopyBox title="Dune Buggy" text={"https://create.roblox.com/store/asset/109595714203462/Dune-Buggy"} />],
          ["Dispenser Mechanisms", urls[2]?.url, <GemDispenserScript />],
          ["Force Fields", urls[3]?.url, <ForceFieldScript />],
          ["Organize the Hunt", urls[4]?.url],
          ["Build the Jump Spring", urls[5]?.url, <CopyBox title="Jump Spring Mesh" text={"https://create.roblox.com/store/asset/93604176420928/Spring"} />, <JumpSpringScript />],
          ["Speed Staff of Speed", urls[6]?.url, <SpeedStaffScript />],
          ["Unlocking Items & Player Rewards", urls[7]?.url, <CopyBox title="Temple" text={"https://create.roblox.com/store/asset/121562288120510/Temple"} />, <BorderScript />],
          ["Make a timer with GUI's", urls[8]?.url, <TimerScript />, <TextLabelScript />]
        ];

        setTutorials(dynamicTutorials);
        setProjectName(dynamicTutorials[0][0]); // Set initial project name
        setProjectVideo(dynamicTutorials[0][1]); // Set initial project video
      }
      setLoading(false); // Set loading to false after fetching
    });
  }, []);

  const changeVideo = (tutorial, index) => {
    setProjectName(tutorial[0]);
    setProjectVideo(tutorial[1]);
    setTutorialIndex(index);
  };

  return (
    <div className='roblox-tutorial'>
      {
        loading === true ?
        <div className='loadcontainer'>
          <Loader />
        </div>
        :
        <div className='tutorial-structure'>
          <div className='tutorial-structure-left'>
            <Video title={projectName} vidfile={projectVideo} />
            
            {tutorials[tutorialIndex]?.length >= 3 &&
              tutorials[tutorialIndex]
                .slice(2) // Extracts all elements starting from index 2
                .map((tutorial, index) => (
                <div key={index}>{tutorial}</div> // Replace with your desired rendering logic
            ))}
          </div>

          <div className='tutorial-structure-right'>
            <div className='project-name'>
              <h3>Gem Hunter</h3>
            </div>

            {tutorials.map((tutorial, index) => (
              <div
                key={index}
                onClick={() => changeVideo(tutorial, index)}
                className={`tutorial-card ${tutorialIndex === index ? 'selected' : ''}`}
              >
                <div className='tutorial-card-left'>
                  <p className='index'>{index + 1}.</p>
                </div>

                <div className='tutorial-card-right'>
                  <video
                    className='video-preview'
                    preload="metadata" // stops it from downloading full video
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source src={tutorial[1]} type="video/mp4" />
                    Failed to load video tag.
                  </video>

                  <p className='tutorial-name'>{tutorial[0]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

export default GemHunter