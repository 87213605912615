import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import api from '../../api/api';
import "./card.css";


const Card = ({ subscriptionId, loading, setLoading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [currentCard, setCurrentCard] = useState(null);

    useEffect(() => {
        if (subscriptionId != "") {
            const fetchCurrentPaymentMethod = async () => {
                try {
                    const response = await api.post(`${process.env.REACT_APP_API_URL}/get-current-payment-method`, {
                        subscriptionId
                    });
                    setCurrentCard(response.data);
                } catch (err) {
                    setError('Failed to load current payment method');
                } finally {
                    setLoading(false);
                }
            };
    
            fetchCurrentPaymentMethod();
        }
    }, [subscriptionId]);

    const updateInfo = async () => {
        setLoading(true);

        try {
            const { data: { clientSecret } } = await api.post(`${process.env.REACT_APP_API_URL}/create-setup-intent`, {
                subscriptionId
            });

            
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });


            if (result.error) {
                toast.error("Error!")
            } else {
                await api.post(`${process.env.REACT_APP_API_URL}/update-subscription-payment`, {
                    subscriptionId,
                    paymentMethodId: result.setupIntent.payment_method
                });


                const response = await api.post(`${process.env.REACT_APP_API_URL}/get-current-payment-method`, {
                    subscriptionId
                });

                setCurrentCard(response.data);
                toast.success('Card updated successfully!');
            }
        } catch (err) {
            toast.error('An error occurred while updating your card');
        }

        setLoading(false);
    };


    const cardElementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#32325d',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSmoothing: 'antialiased',
                lineHeight: '24px',
                '::placeholder': {
                    color: '#aab7c4',
                },
                ':hover': {
                    color: '#000000',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
            complete: {
                color: '#4CAF50',
                ':hover': {
                    color: '#45a049',
                },
            },
        },
        hidePostalCode: true,
        iconStyle: 'solid',
        disabled: !stripe || !elements,
    };




    return (
        <div className="card-container">
            {currentCard && (
                <div className="current-card">
                    <h3>Current Payment Method</h3>
                    {currentCard.hasCard ? (
                        <p>
                            {currentCard.card.brand.toUpperCase()} ending in {currentCard.card.last4}<br />
                            Expires: {currentCard.card.expMonth}/{currentCard.card.expYear}
                        </p>
                    ) : (
                        <p>No payment method on file</p>
                    )}
                </div>
            )}
            <div className="card-input">
                <h3>Update Card</h3>
                <CardElement options={cardElementOptions} />

                <button onClick={()=>updateInfo()} className='update-button'>Update Card Info</button>
            </div>
        </div>
    );
};

export default Card;