import React, { useState, useEffect } from 'react';
import './account.css';
import { ToastContainer, toast } from 'react-toastify';
import Navbar from '../../components/navbar/navbar';
import Sidebar from '../../components/sidebar/sidebar';
import Loader from '../../components/loader/loader';
import { FaPencil } from "react-icons/fa6";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import api from '../../api/api';
import Card from './card';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Account = () => {  
  const _id = JSON.parse(localStorage.getItem("user"))?._id
  var [childNameFirst, setChildNameFirst] = useState("");
  var [childNameLast, setChildNameLast] = useState("");
  var [birthday, setBirthday] = useState("");
  var [parentNameFirst, setParentNameFirst] = useState("");
  var [parentNameLast, setParentNameLast] = useState("");
  var [email, setEmail] = useState("");
  var [phoneNumber, setPhoneNumber] = useState("");
  var [timezone, setTimezone] = useState("");
  var [loading, setLoading] = useState(false);

  const [currentCard, setCurrentCard] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState('');


  const getUser = async () => {
    setLoading(true)
    await api.post(`${process.env.REACT_APP_API_URL}/single-user`, {
      id: _id
    }).then((res) => {
      setParentNameFirst(res.data.parentNameFirst)
      setParentNameLast(res.data.parentNameLast)
      setPhoneNumber(res.data.phoneNumber)
      setEmail(res.data.email)

      setChildNameFirst(res.data.childNameFirst)
      setChildNameLast(res.data.childNameLast)
      setBirthday(res.data.childAge)
      setTimezone(res.data.timeZone)
      setSubscriptionId(res.data.subID)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getUser()
  }, [])


  return (
    <div className='account' >
      <ToastContainer />
      <Sidebar />

      <div className='page-right'>
        <Navbar />

            <div style={ loading === true ? { display: "flex" } : { display: "none" }} className='account__loading'>
              <Loader />
            </div>

            <div style={ loading === false ? { display: "flex" } : { display: "none" }} className='account__container'>

              <div className='account-form'>
                <h2>Your Account</h2>

                <p className='info'>Parent Info</p>

                <div className='double-container'>
                  <div className='input-container'>
                    <input type='text' value={parentNameFirst} />
                  </div>

                  <div className='input-container'>
                    <input type='text' value={parentNameLast} />
                  </div>
                </div>

                <div className='double-container'>
                  <div className='input-container'>
                    <input type='text' value={email} />
                  </div>

                  <div className='input-container'>
                    <input type='text' value={phoneNumber} />
                  </div>
                </div>

                <p className='info'>Student Info</p>

                <div className='double-container'>
                  <div className='input-container'>
                    <input type='text' value={childNameFirst} />
                  </div>

                  <div className='input-container'>
                    <input type='text' value={childNameLast} />
                  </div>
                </div>

                <div className='single-container'>
                  <div className='input-container'>
                    <input type="date" value={birthday} />
                  </div>
                </div>

                <div className='single-container'>
                  <div className='input-container'>
                    <input type="text" value={timezone} />
                  </div>
                </div>

              </div>

              <div className='card-form'>
                <Elements stripe={stripePromise} >
                  <Card subscriptionId={subscriptionId} loading={loading} setLoading={setLoading} />
                </Elements>
              </div>

            </div>

      </div>
    </div>
  )
}

export default Account