import React from 'react';
import { useLocation } from 'react-router-dom';
import './robloxTutorial.css';
import Navbar from '../../components/navbar/navbar';
import Start from '../../tutorials/roblox/start';

import Jumpquest from '../../tutorials/roblox/jumpquest';
import GemHunter from '../../tutorials/roblox/gemhunter';
import CastleConundrum from '../../tutorials/roblox/castleconundrum';
import SoccerShowdown from '../../tutorials/roblox/soccershowdown';
import CheeseChase from '../../tutorials/roblox/cheesechase';
import SaveTheCity from '../../tutorials/roblox/savethecity';

import './robloxTutorial.css';


const RobloxTutorial = () => {
  const location = useLocation();

  return (
    <div>
        <Navbar />

        {
            location.pathname === "/roblox-guide/start" &&
            <Start />
        }

        {
            location.pathname === "/roblox-guide/jumpquest" &&
            <Jumpquest />
        }

        {
            location.pathname === "/roblox-guide/gemhunter" &&
            <GemHunter />
        }

        {
            location.pathname === "/roblox-guide/castleconundrum" &&
            <CastleConundrum />
        }

        {
            location.pathname === "/roblox-guide/soccershowdown" &&
            <SoccerShowdown />
        }

        {
            location.pathname === "/roblox-guide/cheesechase" &&
            <CheeseChase />
        }

        {
            location.pathname === "/roblox-guide/savethecity" &&
            <SaveTheCity />
        }

    </div>
  )
}

export default RobloxTutorial