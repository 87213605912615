import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Navbar from '../../components/navbar/navbar';
import './classesondeck.css';
import Attendance from '../../components/teacher-comps/attendance/attendance';
import Details from '../../components/teacher-comps/details/details';
import api from '../../api/api';
import Loader from '../../components/loader/loader';
import Schedule from '../../components/teacher-comps/schedule/schedule';
import Reschedules from '../../components/teacher-comps/reschedules/reschedules';

const ClassesOnDeck = () => {
  const _id = JSON.parse(localStorage.getItem("user"))?._id

  var [loading, setLoading] = useState(false);
  var [path, setPath] = useState("scratch");
  var [project, setProject] = useState("Apple Catch");
  var [status, setStatus] = useState(false);
  var [tempID, setTempID] = useState("");


  var [details, setDetails] = useState(false);
  var [detailsName, setDetailsName] = useState("");
  var [detailsPath, setDetailsPath] = useState("scratch")

  var [scratch, setScratch] = useState([]);
  var [python, setPython] = useState([]);
  var [roblox, setRoblox] = useState([]);
  var [fullstack, setFullstack] = useState([]);

  var [dayDetails, setDayDetails] = useState();
  var [selectedCert, setSelectedCert] = useState();
  var [studentID, setStudentID] = useState("");
  var [badge, setBadge] = useState(null);

  var [attendance, setAttendance] = useState(false);
  var [attendanceName, setAttendanceName] = useState("");
  var [attendanceMessage, setAttendanceMessage] = useState("");
  var [attendanceEmail, setAttendanceEmail] = useState("");
  var [IDofStudent, setIDofStudent] = useState("");
  var [classID, setClassID] = useState();


  var [monday, setMonday] = useState([]);
  var [tuesday, setTuesday] = useState([]);
  var [wednesday, setWednesday] = useState([]);
  var [thursday, setThursday] = useState([]);
  var [friday, setFriday] = useState([]);
  var [saturday, setSaturday] = useState([]);
  var [reschedules, setReschedules] = useState([]);




  function sortByRescheduledTeacherDate(dataArray) {
    return dataArray.sort((a, b) => {
      const dateA = new Date(a.rescheduledTeacherDate);
      const dateB = new Date(b.rescheduledTeacherDate);
      return dateA - dateB;
    });
  }


  const getTeacherClasses = async () => {
    setLoading(true)
    try {
      const res = await api.post(`${process.env.REACT_APP_API_URL}/classes-of-teacher`, { _id });

      function organizeByDayOfWeek(arr) {
        let monday = [];
        let tuesday = [];
        let wednesday = [];
        let thursday = [];
        let friday = [];
        let saturday = [];
        let sunday = [];

        arr.forEach((obj) => {
          switch (obj.teacherDOW.toLowerCase()) {
            case 'monday':
              monday.push(obj);
              break;
            case 'tuesday':
              tuesday.push(obj);
              break;
            case 'wednesday':
              wednesday.push(obj);
              break;
            case 'thursday':
              thursday.push(obj);
              break;
            case 'friday':
              friday.push(obj);
              break;
            case 'saturday':
              saturday.push(obj);
              break;
            default:
              console.warn(`Unknown day: ${obj.teacherDOW}`);
          }
        });

        var week = {
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday
        }
        return week;
      }

      const organizedData = organizeByDayOfWeek(res.data);

      function sortSchedulesByTime(schedule) {
        function parseTime(timeString) {
          const [time, modifier] = timeString.split(/(am|pm)/);
          let [hours, minutes] = time.split(":").map(Number);
          if (modifier === "pm" && hours !== 12) hours += 12;
          if (modifier === "am" && hours === 12) hours = 0;
          return new Date(0, 0, 0, hours, minutes);
        }

        function formatTime(timeString) {
          const [time, modifier] = timeString.split(/(am|pm)/);
          let [hours] = time.split(":").map(Number);
          return `${hours} ${modifier.toUpperCase()}`;
        }

        Object.keys(schedule).forEach(day => {
          schedule[day].sort((a, b) => {
            const startA = parseTime(a.teacherTime.split("-")[0]);
            const startB = parseTime(b.teacherTime.split("-")[0]);
            return startA - startB;
          });

          schedule[day] = schedule[day].map(entry => ({
            ...entry,
            teacherTime: formatTime(entry.teacherTime.split("-")[0])
          }));
        });

        return schedule;
      }


      const sortedSchedule = sortSchedulesByTime(organizedData);
      console.log(sortedSchedule)
      setMonday(sortedSchedule.monday)
      setTuesday(sortedSchedule.tuesday)
      setWednesday(sortedSchedule.wednesday)
      setThursday(sortedSchedule.thursday)
      setFriday(sortedSchedule.friday)
      setSaturday(sortedSchedule.saturday)

      await api.post(`${process.env.REACT_APP_API_URL}/teacher-reschedules`, {
        teacherID: _id
      }).then((res) => {
        var orderedSchedule = sortByRescheduledTeacherDate(res.data)
        setReschedules(orderedSchedule)
      })
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeacherClasses()
  }, [])







  const changeAttendance = async (dayInfo) => {
    window.scrollTo(0, 0);
    setAttendanceName(dayInfo?.studentName)
    setTempID(dayInfo?.studentID)
    setClassID(dayInfo?._id)

    await api.post(`${process.env.REACT_APP_API_URL}/get-badge`, {
      studentID: dayInfo?.studentID
    }).then((res) => {
      setBadge(res.data[0])
    })

    await api.post(`${process.env.REACT_APP_API_URL}/single-user`, {
      id: dayInfo?.studentID
    }).then((res) => {
      setAttendanceEmail(res.data.email)
      setIDofStudent(res.data._id)
    }).catch((err) => {
      console.log(err)
    })

    setAttendance(true)
  }


  const changeDetails = async (day) => {
    window.scrollTo(0, 0);

    setDetails(true)
    setDayDetails(day)
    setDetailsName(day?.studentName)
    setStudentID(day?.studentID)


    await api.post(`${process.env.REACT_APP_API_URL}/get-badge`, {
      studentID: day?.studentID
    }).then((res) => {
      setScratch(res.data[0].scratch)
      setPython(res.data[0].python)
      setRoblox(res.data[0].roblox)
      setFullstack(res.data[0].fullstack)

    }).catch((err) => {
      console.log(err)
    })

  }


  const days = [
    { name: 'Monday', classes: monday },
    { name: 'Tuesday', classes: tuesday },
    { name: 'Wednesday', classes: wednesday },
    { name: 'Thursday', classes: thursday },
    { name: 'Friday', classes: friday },
    { name: 'Saturday', classes: saturday },
  ];

  return (
    <div>
      <ToastContainer />
      <Navbar />


      {
        details &&
        <Details
          detailsName={detailsName}
          detailsPath={detailsPath}
          setDetailsPath={setDetailsPath}
          setSelectedCert={setSelectedCert}
          details={details}
          setDetails={setDetails}
          scratch={scratch}
          python={python}
          roblox={roblox}
          fullstack={fullstack}
        />
      }


      {
        attendance &&
        <Attendance
          badge={badge}
          path={path}
          setPath={setPath}
          project={project}
          setProject={setProject}
          setAttendance={setAttendance}
          status={status}
          setStatus={setStatus}
          attendanceName={attendanceName}
          attendanceMessage={attendanceMessage}
          setAttendanceMessage={setAttendanceMessage}

          attendanceEmail={attendanceEmail}
          IDofStudent={IDofStudent}
          _id={_id}
          classID={classID}
          tempID={tempID}
          setLoading={setLoading}
        />
      }


      {
        loading === true ?
          <div className='schedule__loader'>
            <Loader />
          </div>
          :
          <div className='schedule__container'>

            {
              reschedules?.length > 0 &&
              <Reschedules reschedules={reschedules} />
            }

            <div className='classes__container'>

              {days?.map((day) => (
                <Schedule
                  key={day.name}
                  dayName={day.name}
                  classes={day.classes}
                  changeDetails={changeDetails}
                  changeAttendance={changeAttendance}
                />
              ))}

            </div>

          </div>
      }

    </div>
  )
}

export default ClassesOnDeck