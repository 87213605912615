import React, { useEffect, useState } from 'react';
import Video from '../../components/video/video';
import CodeCard from '../../components/codecard/codecard';
import CopyBox from '../../components/copybox/copybox';
import Indent from '../../components/indent/indent';
import api from "../../api/api";
import Loader from '../../components/loader/loader';



const LeaderboardScript = () => {
  return (
    <CodeCard title={"Leaderboard Script"}>
      <span class="keyword">function</span> <span class="identifier">createLeaderboard</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Folder"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"leaderstats"</span><br />
      <Indent /><span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">points</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"IntValue"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">points</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Points"</span><br />
      <Indent /><span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><span class="identifier">points</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">PlayerAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">createLeaderboard</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const CheeseSpawnerScript = () => {
  return (
    <CodeCard title={"Cheese Spawner Script"}>
      <span class="keyword">local</span> <span class="identifier">cheeseSpawner</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">spawnInterval</span> <span class="operator">=</span> <span class="number">1</span><br />
      <br />
      <span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="identifier">spawnInterval</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">cheeseBlock</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Part"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">cheeseBlock</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"CheeseBlock"</span><br />
      <Indent /><span class="identifier">cheeseBlock</span><span class="operator">.</span><span class="property">Size</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">2</span><span class="punctuation">,</span> <span class="number">0.5</span><span class="punctuation">,</span> <span class="number">2</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">cheeseBlock</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"New Yeller"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">cheeseBlock</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">=</span> <span class="identifier">cheeseSpawner</span><span class="operator">.</span><span class="property">Position</span><br />
      <Indent /><span class="identifier">cheeseBlock</span><span class="operator">.</span><span class="property">Anchored</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><span class="identifier">cheeseBlock</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">workspace</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="keyword">function</span> <span class="identifier">onTouched</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">character</span> <span class="keyword">and</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"Model"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">humanoid</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Points"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">+</span> <span class="number">1</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">cheeseBlock</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="identifier">cheeseBlock</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onTouched</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  );
}

const PusherScript = () => {
  return (
    <CodeCard title={"Pusher Script"}>
      <span class="keyword">local</span> <span class="identifier">pusher</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">random</span> <span class="operator">=</span> <span class="builtin">Random</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">push</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">part</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"Part"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">~=</span> <span class="string">"pusher"</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">directions</span> <span class="operator">=</span> <span class="punctuation">&#123;</span><br />
      <Indent /><Indent /><Indent /><span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><span class="punctuation">,</span><br />
      <Indent /><Indent /><Indent /><span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">-1</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><span class="punctuation">,</span><br />
      <Indent /><Indent /><Indent /><span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">1</span><span class="punctuation">)</span><span class="punctuation">,</span><br />
      <Indent /><Indent /><Indent /><span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">-1</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="punctuation">&#125;</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">pushDirection</span> <span class="operator">=</span> <span class="identifier">directions</span><span class="punctuation">[</span><span class="identifier">random</span><span class="operator">:</span><span class="function">NextInteger</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">,</span> <span class="operator">#</span><span class="identifier">directions</span><span class="punctuation">)</span><span class="punctuation">]</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">pushStrength</span> <span class="operator">=</span> <span class="number">50</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">bodyVelocity</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BodyVelocity"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">bodyVelocity</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="identifier">pushDirection</span> <span class="operator">*</span> <span class="identifier">pushStrength</span><br />
      <Indent /><Indent /><span class="identifier">bodyVelocity</span><span class="operator">.</span><span class="property">MaxForce</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">5000</span><span class="punctuation">,</span> <span class="number">5000</span><span class="punctuation">,</span> <span class="number">5000</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">bodyVelocity</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">part</span><br />
      <br />
      <Indent /><Indent /><span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Debris"</span><span class="punctuation">)</span><span class="operator">:</span><span class="function">AddItem</span><span class="punctuation">(</span><span class="identifier">bodyVelocity</span><span class="punctuation">,</span> <span class="number">0.5</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">pusher</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">push</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const KillBrickScript = () => {
  return (
    <CodeCard title={"Kill Brick Script"}>
      <span class="keyword">local</span> <span class="identifier">laser</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">damagePlayer</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">humanoid</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">Health</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">laser</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">damagePlayer</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const PlayerToRat = () => {
  return (
    <CodeCard title={"Player 2 Rat Script"}>
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">ratMesh</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"ratMesh"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">ratSpawner</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">RatSpawner</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">transformCharacterToBlock</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">,</span> <span class="identifier">ratMeshTemplate</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">for</span> <span class="identifier">_</span><span class="punctuation">,</span> <span class="identifier">child</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">character</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">child</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BasePart"</span><span class="punctuation">)</span> <span class="keyword">or</span> <span class="identifier">child</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span> <span class="keyword">or</span> <span class="identifier">child</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">child</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">block</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Part"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">block</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"HumanoidRootPart"</span><br />
      <Indent /><span class="identifier">block</span><span class="operator">.</span><span class="property">Size</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">2</span><span class="punctuation">,</span> <span class="number">1</span><span class="punctuation">,</span> <span class="number">4</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">block</span><span class="operator">.</span><span class="property">Transparency</span> <span class="operator">=</span> <span class="number">1</span><br />
      <Indent /><span class="identifier">block</span><span class="operator">.</span><span class="property">Anchored</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><span class="identifier">block</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><span class="identifier">block</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">character</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">ratMeshClone</span> <span class="operator">=</span> <span class="identifier">ratMeshTemplate</span><span class="operator">:</span><span class="function">Clone</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">ratMeshClone</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">character</span><br />
      <br />
      <Indent /><span class="identifier">ratMeshClone</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="identifier">block</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">*</span> <span class="builtin">CFrame</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">1.8</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">weld</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"WeldConstraint"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">weld</span><span class="operator">.</span><span class="property">Part0</span> <span class="operator">=</span> <span class="identifier">block</span><br />
      <Indent /><span class="identifier">weld</span><span class="operator">.</span><span class="property">Part1</span> <span class="operator">=</span> <span class="identifier">ratMeshClone</span><br />
      <Indent /><span class="identifier">weld</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">ratMeshClone</span><br />
      <br />
      <Indent /><span class="identifier">character</span><span class="operator">.</span><span class="property">PrimaryPart</span> <span class="operator">=</span> <span class="identifier">block</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="number">25</span><br />
      <Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">character</span><br />
      <Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">HealthDisplayDistance</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">NameOcclusion</span> <span class="operator">=</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">NameOcclusion</span><span class="operator">.</span><span class="property">NoOcclusion</span><br />
      <br />
      <Indent /><span class="identifier">character</span><span class="operator">:</span><span class="function">SetPrimaryPartCFrame</span><span class="punctuation">(</span><span class="identifier">ratSpawner</span><span class="operator">.</span><span class="property">CFrame</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">PlayerAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">character</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">character</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><span class="function">transformCharacterToBlock</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">,</span> <span class="identifier">ratMesh</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChildOfClass</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><span class="operator">.</span><span class="property">Died</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">5</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">:</span><span class="function">LoadCharacter</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Wait</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="function">transformCharacterToBlock</span><span class="punctuation">(</span><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="punctuation">,</span> <span class="identifier">ratMesh</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const TrackBoostLevel = () => {
  return (
    <CodeCard title={"Track Boost Level Script"}>
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><br />
      <span class="keyword">local</span> <span class="identifier">PlayerGui</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"PlayerGui"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">BoostGui</span> <span class="operator">=</span> <span class="identifier">PlayerGui</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"BoostGui"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">BoostBar</span> <span class="operator">=</span> <span class="identifier">BoostGui</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"BoostBar"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">BoostLevel</span> <span class="operator">=</span> <span class="identifier">BoostBar</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"BoostLevel"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">points</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Points"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">maxBoosts</span> <span class="operator">=</span> <span class="number">10</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">updateBoostBar</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">totalPoints</span> <span class="operator">=</span> <span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">pointsForBar</span> <span class="operator">=</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">min</span><span class="punctuation">(</span><span class="identifier">totalPoints</span><span class="punctuation">,</span> <span class="identifier">maxBoosts</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">boostPerPoint</span> <span class="operator">=</span> <span class="number">1</span> <span class="operator">/</span> <span class="identifier">maxBoosts</span><br />
      <br />
      <Indent /><span class="identifier">BoostLevel</span><span class="operator">.</span><span class="property">Size</span> <span class="operator">=</span> <span class="builtin">UDim2</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">pointsForBar</span> <span class="operator">*</span> <span class="identifier">boostPerPoint</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">1</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">points</span><span class="operator">.</span><span class="property">Changed</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">updateBoostBar</span><span class="punctuation">)</span><br />
      <br />
      <span class="identifier">updateBoostBar</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const SpeedBoostScript = () => {
  return (
    <CodeCard title={"Speed Boost Script"}>
      <span class="keyword">local</span> <span class="identifier">boostDuration</span> <span class="operator">=</span> <span class="number">2</span><br />
      <span class="keyword">local</span> <span class="identifier">boostSpeed</span> <span class="operator">=</span> <span class="number">50</span><br />
      <span class="keyword">local</span> <span class="identifier">normalSpeed</span> <span class="operator">=</span> <span class="number">25</span><br />
      <span class="keyword">local</span> <span class="identifier">costPerBoost</span> <span class="operator">=</span> <span class="number">1</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">activateSpeedBoost</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChildOfClass</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">humanoid</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="identifier">boostSpeed</span><br />
      <Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="identifier">boostDuration</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="identifier">normalSpeed</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onKeyPress</span><span class="punctuation">(</span><span class="identifier">input</span><span class="punctuation">,</span> <span class="identifier">gameProcessed</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">gameProcessed</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">return</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">LeftShift</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">&gt;=</span> <span class="identifier">costPerBoost</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">-</span> <span class="identifier">costPerBoost</span><br />
      <Indent /><Indent /><Indent /><span class="function">activateSpeedBoost</span><span class="punctuation">(</span><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"UserInputService"</span><span class="punctuation">)</span><span class="operator">.</span><span class="property">InputBegan</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onKeyPress</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const MoveBlockScript = () => {
  return (
    <CodeCard title={"Move Block Script"}>
      <span class="keyword">local</span> <span class="identifier">ChaseBlock</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">speed</span> <span class="operator">=</span> <span class="number">20</span><br />
      <span class="keyword">local</span> <span class="identifier">killRange</span> <span class="operator">=</span> <span class="number">5</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">keepUpright</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">currentCFrame</span> <span class="operator">=</span> <span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">CFrame</span><br />
      <Indent /><Indent /><span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">CFrame</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">currentCFrame</span><span class="operator">.</span><span class="property">Position</span><span class="punctuation">)</span> <span class="operator">*</span> <span class="builtin">CFrame</span><span class="punctuation">.</span><span class="function">Angles</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">rad</span><span class="punctuation">(</span><span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">Orientation</span><span class="punctuation">.</span><span class="property">Y</span><span class="punctuation">)</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">0.01</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">findNearestPlayer</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">nearestPlayer</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">shortestDistance</span> <span class="operator">=</span> <span class="builtin">math</span><span class="operator">.</span><span class="property">huge</span><br />
      <br />
      <Indent /><span class="keyword">for</span> <span class="punctuation">_,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">character</span> <span class="keyword">and</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">distance</span> <span class="operator">=</span> <span class="punctuation">(</span><span class="identifier">character</span><span class="operator">.</span><span class="property">HumanoidRootPart</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">-</span> <span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">Position</span><span class="punctuation">)</span><span class="operator">.</span><span class="property">magnitude</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">distance</span> <span class="operator">&lt;</span> <span class="identifier">shortestDistance</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">shortestDistance</span> <span class="operator">=</span> <span class="identifier">distance</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">nearestPlayer</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="keyword">return</span> <span class="identifier">nearestPlayer</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">moveToPlayer</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">character</span> <span class="keyword">and</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">direction</span> <span class="operator">=</span> <span class="punctuation">(</span><span class="identifier">character</span><span class="operator">.</span><span class="property">HumanoidRootPart</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">-</span> <span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">Position</span><span class="punctuation">)</span><span class="operator">.</span><span class="property">unit</span><br />
      <Indent /><Indent /><span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="identifier">direction</span> <span class="operator">*</span> <span class="identifier">speed</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">targetPosition</span> <span class="operator">=</span> <span class="identifier">character</span><span class="operator">.</span><span class="property">HumanoidRootPart</span><span class="operator">.</span><span class="property">Position</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">blockPosition</span> <span class="operator">=</span> <span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">Position</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">lookAt</span> <span class="operator">=</span> <span class="builtin">CFrame</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">blockPosition</span><span class="punctuation">,</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">targetPosition</span><span class="punctuation">.</span><span class="property">X</span><span class="punctuation">,</span> <span class="identifier">blockPosition</span><span class="punctuation">.</span><span class="property">Y</span><span class="punctuation">,</span> <span class="identifier">targetPosition</span><span class="punctuation">.</span><span class="property">Z</span><span class="punctuation">)</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="identifier">lookAt</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">killOnContact</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">for</span> <span class="punctuation">_,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">character</span> <span class="keyword">and</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">distance</span> <span class="operator">=</span> <span class="punctuation">(</span><span class="identifier">character</span><span class="operator">.</span><span class="property">HumanoidRootPart</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">-</span> <span class="identifier">ChaseBlock</span><span class="operator">.</span><span class="property">Position</span><span class="punctuation">)</span><span class="operator">.</span><span class="property">magnitude</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">distance</span> <span class="operator">&lt;=</span> <span class="identifier">killRange</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChildOfClass</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><span class="operator">:</span><span class="function">TakeDamage</span><span class="punctuation">(</span><span class="number">100</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">0.1</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="builtin">spawn</span><span class="punctuation">(</span><span class="identifier">keepUpright</span><span class="punctuation">)</span><br />
      <span class="builtin">spawn</span><span class="punctuation">(</span><span class="identifier">killOnContact</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">nearestPlayer</span> <span class="operator">=</span> <span class="function">findNearestPlayer</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">nearestPlayer</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="function">moveToPlayer</span><span class="punctuation">(</span><span class="identifier">nearestPlayer</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">0.1</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}

const OnCatAttack = () => {
  return (
    <CodeCard title={"On Cat Attack Script"}>
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><br />
      <span class="keyword">local</span> <span class="identifier">playerGui</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"PlayerGui"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">showAttackEffect</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">attackGui</span> <span class="operator">=</span> <span class="identifier">playerGui</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"CatAttackGui"</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">frame</span> <span class="operator">=</span> <span class="identifier">attackGui</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Frame"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="identifier">attackGui</span><span class="operator">.</span><span class="property">Enabled</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><span class="keyword">for</span> <span class="identifier">i</span> <span class="operator">=</span> <span class="number">1</span><span class="punctuation">,</span> <span class="number">3</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="identifier">frame</span><span class="operator">.</span><span class="property">BackgroundTransparency</span> <span class="operator">=</span> <span class="number">0.3</span><br />
      <Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">0.2</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">frame</span><span class="operator">.</span><span class="property">BackgroundTransparency</span> <span class="operator">=</span> <span class="number">0.6</span><br />
      <Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">0.2</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <Indent /><span class="identifier">attackGui</span><span class="operator">.</span><span class="property">Enabled</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">monitorCharacter</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">character</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">return</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">Died</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">()</span><br />
      <Indent /><Indent /><span class="builtin">print</span><span class="punctuation">(</span><span class="string">"Player has died"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="function">showAttackEffect</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">monitorCharacter</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">if</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span> <span class="keyword">then</span><br />
      <Indent /><span class="function">monitorCharacter</span><span class="punctuation">(</span><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}


const CheeseChase = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectVideo, setProjectVideo] = useState('');
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchVideoUrls = async (fileNames) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/videos`, { fileNames });
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching video URLs:', error.response?.data || error.message);
      return [];
    }
  };

  useEffect(() => {
    const fileNames = [
      'cheesechase/CHEESECHASE01.mp4', 
      'cheesechase/CHEESECHASE02.mp4',
      'cheesechase/CHEESECHASE03.mp4',
      'cheesechase/CHEESECHASE04.mp4',
      'cheesechase/CHEESECHASE05.mp4',
      'cheesechase/CHEESECHASE06.mp4'
    ];

    fetchVideoUrls(fileNames).then((urls) => {
      if (urls && Array.isArray(urls)) {
        setVideoUrls(urls);

        const dynamicTutorials = [
          ["Build the Cheese Factory", urls[0]?.url, <LeaderboardScript />, <CheeseSpawnerScript />, <PusherScript />, <KillBrickScript />],
          ["Player 2 Rat", urls[1]?.url, <CopyBox title="Rat Mesh" text={"https://create.roblox.com/store/asset/131165769594779/Rat-Mesh"} />, <PlayerToRat />],
          ["Track Boost Level", urls[2]?.url, <TrackBoostLevel />, <SpeedBoostScript />],
          ["Cheese Time of Madness", urls[3]?.url, <CopyBox title="Cat Model" text={"https://create.roblox.com/store/asset/120901941971097/Blocky-Cat"} />, <MoveBlockScript />],
          ["Cat Attack!!!", urls[4]?.url, <OnCatAttack />],
          ["Finishing Touches", urls[5]?.url, <CopyBox title="Lamp Model" text={"https://create.roblox.com/store/asset/125829159860880/Lamp"} />]
        ];

        setTutorials(dynamicTutorials);
        setProjectName(dynamicTutorials[0][0]); 
        setProjectVideo(dynamicTutorials[0][1]);
      }
      setLoading(false); 
    });
  }, []);



  const changeVideo = (tutorial, index) => {
    setProjectName(tutorial[0]);
    setProjectVideo(tutorial[1]);
    setTutorialIndex(index);
  };


  return (
    <div className='roblox-tutorial'>

      {
        loading === true ?
        <div className='loadcontainer'>
          <Loader />
        </div>
        :
        <div className='tutorial-structure'>
          <div className='tutorial-structure-left'>
            <Video title={projectName} vidfile={projectVideo} />
            
            {tutorials[tutorialIndex]?.length >= 3 &&
              tutorials[tutorialIndex]
                .slice(2) // Extracts all elements starting from index 2
                .map((tutorial, index) => (
                <div key={index}>{tutorial}</div> // Replace with your desired rendering logic
            ))}
          </div>

          <div className='tutorial-structure-right'>
            <div className='project-name'>
              <h3>Cheese Chase</h3>
            </div>

            {tutorials.map((tutorial, index) => (
              <div
                key={index}
                onClick={() => changeVideo(tutorial, index)}
                className={`tutorial-card ${tutorialIndex === index ? 'selected' : ''}`}
              >
                <div className='tutorial-card-left'>
                  <p className='index'>{index + 1}.</p>
                </div>

                <div className='tutorial-card-right'>
                  <video
                    className='video-preview'
                    preload="metadata" // stops it from downloading full video
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source src={tutorial[1]} type="video/mp4" />
                    Failed to load video tag.
                  </video>

                  <p className='tutorial-name'>{tutorial[0]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      }

    </div>
  )
}

export default CheeseChase