import React from 'react';
import './reschedules.css';

const Reschedules = ({ reschedules }) => {



    function getOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
            return 'th';
        }

        const lastDigit = day % 10;
        switch (lastDigit) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    function formatDateToClassString(dateString) {
        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);

        const daysOfWeek = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];

        const dayName = daysOfWeek[date.getDay()];
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const monthName = months[date.getMonth()];
        const dayNumber = date.getDate();
        const suffix = getOrdinalSuffix(dayNumber);
        const ordinalDay = `${dayNumber}${suffix}`;
        return `${dayName}, ${monthName} ${ordinalDay}`;
    }







    return (
        <div className='reschedule-container'>
            <table>
                <tr className='first-row'>
                    <th>Reschedule:</th>

                    <th className='cancelled-class'>Cancelled Class:</th>

                    <th>Student:</th>
                </tr>

                {
                    reschedules?.map((reschedule) => {
                        return (
                            <tr>
                                <td>{formatDateToClassString(reschedule?.rescheduledTeacherDate)} <p>{reschedule?.rescheduledTeacherTime}</p></td>

                                <td className='cancelled-class'>{formatDateToClassString(reschedule?.canceledTeacherDate)}</td>

                                <td>{reschedule?.studentName}</td>
                            </tr>
                        )
                    })
                }

            </table>
        </div>
    )
}

export default Reschedules