import React from 'react';
import { useLocation } from 'react-router-dom';
import './fullstackTutorial.css';
import Navbar from '../../components/navbar/navbar';
import Start from '../../tutorials/fullstack/start';
import AboutCapybaras from '../../tutorials/fullstack/aboutCapybaras';
import PepesTravel from '../../tutorials/fullstack/pepesTravel';
import Frog from '../../tutorials/fullstack/frog';
import SquidInk from '../../tutorials/fullstack/squidInk';
import FinFancy from '../../tutorials/fullstack/finFancy';
import SunnySideForecast from '../../tutorials/fullstack/sunnySideForecast';
import BuildShip from '../../tutorials/fullstack/buildShip';
import ComfyCouchCo from '../../tutorials/fullstack/comfyCouchCo';
import TopFiveBooks from '../../tutorials/fullstack/topFiveBooks';
import CookieClicker from '../../tutorials/fullstack/cookieClicker';
import ColorChanger from '../../tutorials/fullstack/colorChanger';
import RobuxCalc from '../../tutorials/fullstack/robuxCalc';
import RockPaperScissors from '../../tutorials/fullstack/rockPaperScissors';

const FullstackTutorial = () => {
  const location = useLocation();

  return (
    <div>
        <Navbar />

        {
            location.pathname === "/fullstack-guide/start" &&
            <Start />
        }

        {
            location.pathname === "/fullstack-guide/about-capybaras" &&
            <AboutCapybaras />
        }

        {
            location.pathname === "/fullstack-guide/pepes-travel" &&
            <PepesTravel />
        }

        {
            location.pathname === "/fullstack-guide/poison-dart-frog" &&
            <Frog />
        }

        {
            location.pathname === "/fullstack-guide/squidink-studios" &&
            <SquidInk />
        }

        {
            location.pathname === "/fullstack-guide/finfancy" &&
            <FinFancy />
        }

        {
            location.pathname === "/fullstack-guide/sunny-side-forecast" &&
            <SunnySideForecast />
        }

        {
            location.pathname === "/fullstack-guide/build-a-ship" &&
            <BuildShip />
        }

        {
            location.pathname === "/fullstack-guide/comfy-couch-co" &&
            <ComfyCouchCo />
        }

        {
            location.pathname === "/fullstack-guide/top-five-books" &&
            <TopFiveBooks />
        }

        {
            location.pathname === "/fullstack-guide/cookie-clicker" &&
            <CookieClicker />
        }

        {
            location.pathname === "/fullstack-guide/color-changer" &&
            <ColorChanger />
        }

        {
            location.pathname === "/fullstack-guide/robux-calc" &&
            <RobuxCalc />
        }

        {
            location.pathname === "/fullstack-guide/rock-paper-scissors" &&
            <RockPaperScissors />
        }

    </div>
  )
}

export default FullstackTutorial