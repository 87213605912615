import React, { useEffect, useState } from 'react';
import crypto from 'crypto-browserify';
import './classCard.css';

const ClassCard = ({ day, changeDetails, changeAttendance }) => {
  var [decryptedUrl, setDecryptedUrl] = useState("");

  function decrypt(text) {
    const [ivHex, encryptedTextHex] = text.split(':');

    const decipher = crypto.createDecipheriv(
      'aes-256-cbc',
      process.env.REACT_APP_SECRET_ENCRYPT_KEY,
      Buffer.from(ivHex, 'hex')
    );

    let decrypted = decipher.update(encryptedTextHex, 'hex', 'utf8');
    decrypted += decipher.final('utf8');

    return decrypted;
  }

  useEffect(() => {
    setDecryptedUrl(decrypt(day?.join_url))
  }, []);


  return (
    <div className='class__card'>
      <div className='class__details'>
        <h2>{day?.teacherTime}</h2>
        <p>{day?.studentName}</p>
      </div>

      <div className='card__button__container' >

        <button onClick={() => changeDetails(day)} className='details' >Details</button>


        <button onClick={() => changeAttendance(day)} className='attendance' >Take Attendance</button>

        <button className='meeting'><a target="_blank" href={decryptedUrl} >Start Meeting</a></button>
      </div>
    </div>
  )
}

export default ClassCard