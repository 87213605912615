import React from 'react';
import ClassCard from '../class-card/classCard';

const Schedule = ({ dayName, classes, changeDetails, changeAttendance }) => {
    return (
        <div className='day__container'>
            <h1>{dayName}:</h1>
            <div className='class__card__container'>
                {classes.length > 0 ? (
                    classes.map((day, index) => (
                        <ClassCard
                            key={index}
                            day={day}
                            changeDetails={changeDetails}
                            changeAttendance={changeAttendance}
                        />
                    ))
                ) : (
                    <div className='no-classes-container'>
                        <h2>No Classes</h2>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Schedule