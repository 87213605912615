import React, { useState } from 'react';
import './copybox.css';

const CopyBox = ({ title, text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    // Copy the text to the clipboard
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);

      // Reset the "copied" state after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className='copybox'>
      <h2>{title}</h2>


      <div className='copybox-container'>
        <p className='copybox-text' >
          <code>{text}</code>
        </p>

        <button className='copybox-button' onClick={handleCopy} >
          {copied ? "Copied!" : "Copy"}
        </button>
      </div>
    </div>
  )
}

export default CopyBox