import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';
import Loader from '../../components/loader/loader';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import { DateTime } from 'luxon';
import "./reschedule.css";

const Reschedule = () => {
    const navigate = useNavigate();
    const _id = JSON.parse(localStorage.getItem("user"))?._id;
    const [classtimes, setClasstimes] = useState([]);

    const [teacherDOW, setTeacherDOW] = useState("");
    const [studentName, setStudentName] = useState("");
    const [teacherName, setTeacherName] = useState("");
    const [studentID, setStudentID] = useState("");
    const [teacherID, setTeacherID] = useState("");
    const [teacherEmail, setTeacherEmail] = useState("");
    const [studentTimezone, setStudentTimezone] = useState("");
    const [teacherTimezone, setTeacherTimezone] = useState("");

    const [canceledStudentDate, setCanceledStudentDate] = useState("");
    const [canceledStudentTime, setCanceledStudentTime] = useState("");

    const [canceledTeacherDate, setCanceledTeacherDate] = useState("");
    const [canceledTeacherTime, setCanceledTeacherTime] = useState("");

    const [rescheduledStudentDate, setRescheduledStudentDate] = useState("");
    const [rescheduledStudentTime, setRescheduledStudentTime] = useState("");

    const [rescheduledTeacherDate, setRescheduledTeacherDate] = useState("");
    const [rescheduledTeacherTime, setRescheduledTeacherTime] = useState("");

    const [requestedDate, setRequestedDate] = useState("");
    const [requestedDateAvailability, setRequestedDateAvailability] = useState({});

    const [nextThirty, setNextThirty] = useState([]);
    const [convertedSchedule, setConvertedSchedule] = useState(null);
    const [steps, setSteps] = useState(1);
    const [loading, setLoading] = useState(false);

    // -------------------- HELPER to get next 30 days (excl. Sunday) --------------------
    function getNext30Days() {
        const result = [];
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() + 1);

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        function getOrdinal(n) {
            const s = ['th', 'st', 'nd', 'rd'];
            const v = n % 100;
            return s[(v - 20) % 10] || s[v] || s[0];
        }

        for (let i = 0; i < 30; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + i);

            // Skip Sundays
            if (currentDate.getDay() !== 0) {
                const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                const day = String(currentDate.getDate()).padStart(2, '0');
                const year = currentDate.getFullYear();
                const dateStr = `${month}/${day}/${year}`;

                const dayName = daysOfWeek[currentDate.getDay()];
                const monthName = months[currentDate.getMonth()];
                const dayNum = currentDate.getDate();
                const ordinal = getOrdinal(dayNum);
                const formattedStr = `${dayName}, ${monthName} ${dayNum}${ordinal}`;

                result.push([dateStr, formattedStr]);
            }
        }
        setNextThirty(result);
    }

    // -------------------- HELPER: Converts teacher schedule to student's local days --------------------
    function convertSchedule(targetTimezone, scheduleObject) {
        const teacherTimezone = scheduleObject.timeZone;
        const teacherDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        const dayToNum = {
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6
        };

        const nowInTeacherTZ = DateTime.now().setZone(teacherTimezone);

        // Prepare a fresh result
        const result = {
            ...scheduleObject,
            timeZone: targetTimezone
        };
        teacherDays.forEach(day => {
            result[day] = {}; 
        });

        // For each teacher day...
        for (const teacherDay of teacherDays) {
            if (!scheduleObject[teacherDay]) continue;

            const teacherDayIndex = dayToNum[teacherDay];
            if (!teacherDayIndex) continue;

            const daysToAdd = (teacherDayIndex - nowInTeacherTZ.weekday + 7) % 7;
            const teacherDate = daysToAdd === 0
                ? nowInTeacherTZ.startOf('day')
                : nowInTeacherTZ.plus({ days: daysToAdd }).startOf('day');

            const daySchedule = scheduleObject[teacherDay];
            const availableSlots = Object.entries(daySchedule)
                .filter(([_, status]) => status === 'available')
                .map(([slot]) => slot);

            // Convert each slot
            for (const slot of availableSlots) {
                const [startTime, endTime] = slot.split('-');

                const startParsed = DateTime.fromFormat(startTime.trim(), 'h:mma', { zone: teacherTimezone });
                const endParsed   = DateTime.fromFormat(endTime.trim(),   'h:mma', { zone: teacherTimezone });

                // Build DateTimes in teacher tz
                const startTeacherDt = teacherDate.set({ hour: startParsed.hour, minute: startParsed.minute });
                const endTeacherDt   = teacherDate.set({ hour: endParsed.hour,   minute: endParsed.minute });

                // Convert to student's tz
                const startInTarget = startTeacherDt.setZone(targetTimezone);
                const endInTarget   = endTeacherDt.setZone(targetTimezone);

                const startStr = startInTarget.toFormat('h:mma').toLowerCase();
                const endStr   = endInTarget.toFormat('h:mma').toLowerCase();

                const studentLocalSlot = `${startStr}-${endStr}`;

                // The student's actual day (Sunday, Monday, etc.)
                const localDayName = startInTarget.toFormat('cccc').toLowerCase();

                if (!result[localDayName]) {
                    // Possibly handle Sunday if needed
                    result[localDayName] = {};
                }
                result[localDayName][studentLocalSlot] = 'available';
            }
        }

        setConvertedSchedule(result);
    }

    // -------------------- HELPER: Convert a student's chosen date/time to teacher's local date/time --------------------
    function convertStudentChoiceToTeacherDateTime({
        studentDateString,    // e.g. "03/27/2025"
        studentTimeSlot,      // e.g. "9:00am-10:00am"
        studentZone,
        teacherZone
    }) {
        // 1) Parse the "MM/DD/YYYY"
        const [month, day, year] = studentDateString.split('/').map(Number);

        // 2) Split the timeslot "9:00am-10:00am"
        const [startStr, endStr] = studentTimeSlot.split('-').map(s => s.trim());

        // 3) Create placeholders in the student's zone
        const startTimeDt = DateTime.fromFormat(startStr, 'h:mma', { zone: studentZone });
        const endTimeDt   = DateTime.fromFormat(endStr,   'h:mma', { zone: studentZone });

        // 4) Apply the date to both
        const startStudentDt = startTimeDt.set({ year, month, day });
        const endStudentDt   = endTimeDt.set({   year, month, day });

        // 5) Convert to teacher's zone
        const startTeacherDt = startStudentDt.setZone(teacherZone);
        const endTeacherDt   = endStudentDt.setZone(teacherZone);

        // 6) Format teacher’s local times
        const teacherStartStr = startTeacherDt.toFormat('h:mma').toLowerCase();
        const teacherEndStr   = endTeacherDt.toFormat('h:mma').toLowerCase();
        const teacherSlot     = `${teacherStartStr}-${teacherEndStr}`;

        // 7) Also get the teacher’s local date in "MM/dd/yyyy"
        const teacherMonth = String(startTeacherDt.month).padStart(2, '0');
        const teacherDay   = String(startTeacherDt.day).padStart(2, '0');
        const teacherYear  = startTeacherDt.year;
        const teacherDateString = `${teacherMonth}/${teacherDay}/${teacherYear}`;

        return {
            teacherDateString,
            teacherSlot
        };
    }

    // -------------------- API - teacher schedule fetch --------------------
    const fetchTeacherAvailability = async (studentTimeZone, teacherID) => {
        await api.post(`${process.env.REACT_APP_API_URL}/fetch-teacher-schedule`, {
            teacherID
        }).then((res) => {
            convertSchedule(studentTimeZone, res.data);
        });
    };

    // -------------------- API - get next days for the student's DOW --------------------
    const getNextDays = async (dayOfWeek, stid) => {
        const days = {
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
            sunday: 0
        };

        const result = [];
        const today = new Date();
        const targetDayNum = days[dayOfWeek.toLowerCase()];

        if (targetDayNum === undefined) {
            return 'Invalid day of week provided';
        }

        let currentDate = new Date(today);
        const currentDayNum = currentDate.getDay();
        let daysToAdd = targetDayNum - currentDayNum;
        if (daysToAdd <= 0) {
            daysToAdd += 7;
        }

        currentDate.setDate(currentDate.getDate() + daysToAdd);

        while (currentDate <= new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000)) {
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const year = currentDate.getFullYear();
            result.push(`${month}/${day}/${year}`);

            currentDate.setDate(currentDate.getDate() + 7);
        }

        let takenDates = [];
        await api.post(`${process.env.REACT_APP_API_URL}/reschedules-of-student`, {
            studentID: stid
        }).then((res) => {
            for (let i = 0; i < res.data.length; i++) {
                takenDates.push(res.data[i].canceledStudentDate);
            }
        });

        const finalResult = result.filter(value => !takenDates.includes(value));
        setClasstimes(finalResult);
    };

    // -------------------- API - get the current student class --------------------
    const getStudentClass = async () => {
        setLoading(true);
        await api.post(`${process.env.REACT_APP_API_URL}/classes-of-student`, {
            _id
        }).then(async (res) => {
            // Set existing data
            setCanceledStudentTime(res.data[0].studentTime);
            setCanceledTeacherTime(res.data[0].teacherTime);
            setStudentName(res.data[0].studentName);
            setStudentID(res.data[0].studentID);
            setStudentTimezone(res.data[0].studentTimeZone);
            setTeacherName(res.data[0].teacherName);
            setTeacherID(res.data[0].teacherID);
            setTeacherEmail(res.data[0].teacherEmail);
            setTeacherTimezone(res.data[0].teacherTimeZone);

            // Build next possible days, fetch teacher availability, next 30 days
            await getNextDays(res.data[0].studentDOW, res.data[0].studentID);
            fetchTeacherAvailability(res.data[0].studentTimeZone, res.data[0].teacherID);
            getNext30Days();
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    // -------------------- useEffect to load data on mount --------------------
    useEffect(() => {
        getStudentClass();
    }, []);

    // -------------------- Step logic --------------------
    const stepOne = () => {
        setSteps(1);
    };



    const stepTwo = (classtime) => {
        // 'classtime' is e.g. "04/03/2025" for the student
        setCanceledStudentDate(classtime);
      
        // The canceled *time* is presumably in `canceledStudentTime`? 
        // Or you might need to let the user pick the time as well?
        // Let’s assume you have a function that picks the time 
        // or you have the stored 'studentTime' from that day:
      
        // Convert that date/time to teacher’s zone
        const { teacherDateString, teacherSlot } = convertStudentChoiceToTeacherDateTime({
          studentDateString: classtime,        // "MM/dd/yyyy"
          studentTimeSlot: canceledStudentTime, // e.g. "12:00pm-1:00pm"
          studentZone: studentTimezone,
          teacherZone: teacherTimezone
        });
      
        setCanceledTeacherDate(teacherDateString);
        setCanceledTeacherTime(teacherSlot);
      
        setSteps(2);
      };
      

    const stepThree = (date) => {
        // `date` is e.g. ["03/25/2025", "Tuesday, March 25th"]
        setRescheduledStudentDate(date[0]);  // student's chosen date
        // We'll set teacher's date/time in stepFour based on timeslot
        setRequestedDate(date[1]);

        const [dayOfWeek] = date[1].split(', ');
        const dow = dayOfWeek.toLowerCase();
        setTeacherDOW(dow);

        // This is the set of times from the convertedSchedule,
        // which is keyed by the student's local day name (mon, tues,...)
        setRequestedDateAvailability(convertedSchedule[dow] || {});
        setSteps(3);
    };

    // This is the crucial step where we set both the student's and teacher's date/time
    const stepFour = (timeSlot) => {
        setRescheduledStudentTime(timeSlot);
      
        // Convert student's date/time to teacher's date/time
        const { teacherDateString, teacherSlot } = convertStudentChoiceToTeacherDateTime({
            studentDateString: rescheduledStudentDate,
            studentTimeSlot: timeSlot,
            studentZone: studentTimezone,
            teacherZone: teacherTimezone
        });
      
        setRescheduledTeacherDate(teacherDateString);
        setRescheduledTeacherTime(teacherSlot);
      
        // ---- DERIVE teacher's real day name ----
        const [tMonth, tDay, tYear] = teacherDateString.split('/').map(Number);
        const teacherLocalDate = DateTime.fromObject(
          { year: tYear, month: tMonth, day: tDay },
          { zone: teacherTimezone }
        );
        const teacherLocalDayName = teacherLocalDate.toFormat('cccc').toLowerCase();
      
        // Now this is the actual day in teacher's schedule
        setTeacherDOW(teacherLocalDayName);
      
        setSteps(4);
      };
      

    const backToTwo = () => {
        setSteps(2);
    };

    const backToThree = () => {
        setSteps(3);
    };

    // -------------------- Submitting the reschedule --------------------
    const submitReschedule = async () => {
        setLoading(true);
        await api.post(`${process.env.REACT_APP_API_URL}/create-reschedule`, {
            studentId: studentID, 
            studentName,
            studentTimezone,
            teacherId: teacherID,
            teacherEmail,
            teacherName,
            teacherTimezone,
            canceledStudentDate,
            canceledStudentTime,
            canceledTeacherDate,
            canceledTeacherTime,
            rescheduledStudentDate,
            rescheduledStudentTime,
            rescheduledTeacherDate,
            rescheduledTeacherTime,
            teacherDOW
        }).then(() => {
            toast.success("Success!");
        }).catch(() => {
            navigate('/error-500');
        }).finally(() => {
            setLoading(false);
            setTimeout(() => {
                navigate("/join-meeting");
            }, 2000);
        });
    };

    // -------------------- Formatting helpers for display --------------------
    function getOrdinalSuffix(day) {
        if (day >= 11 && day <= 13) {
            return 'th';
        }
        const lastDigit = day % 10;
        switch (lastDigit) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    function formatDateToClassString(dateString) {
        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);

        const daysOfWeek = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const dayName = daysOfWeek[date.getDay()];
        const monthName = months[date.getMonth()];
        const dayNumber = date.getDate();
        const suffix = getOrdinalSuffix(dayNumber);
        const ordinalDay = `${dayNumber}${suffix}`;
        return `${dayName}, ${monthName} ${ordinalDay}'s Class`;
    }

    // -------------------- Render --------------------
    return (
        <div className='reschedule-page'>
            <ToastContainer />
            <Sidebar />

            <div className='reschedule-page-container'>
                <Navbar />

                {loading ? (
                    <div className='reschedule__loading'>
                        <Loader />
                    </div>
                ) : (
                    <div className='reschedule'>
                        {/* Step 1 */}
                        <div
                            style={steps === 1 ? { display: 'flex' } : { display: 'none' }}
                            className='top-container'
                        >
                            <div className='left'>
                                <h1>Reschedule a classtime.</h1>
                                <h2>Which class time would you like to switch things up for? 😊</h2>
                            </div>
                        </div>

                        {/* Step 2 */}
                        <div
                            style={steps === 2 ? { display: 'flex' } : { display: 'none' }}
                            className='top-container'
                        >
                            <div className='left'>
                                <h1>Reschedule a classtime.</h1>
                                <h2>Pick a day to have your reschedule. 📅</h2>
                            </div>
                            <p onClick={() => stepOne()} className='back-button'>Go Back</p>
                        </div>

                        {/* Step 3 */}
                        <div
                            style={steps === 3 ? { display: 'flex' } : { display: 'none' }}
                            className='top-container'
                        >
                            <div className='left'>
                                <h1>Reschedule a classtime.</h1>
                                <h2>
                                    Here are your teacher's available times for{' '}
                                    <strong>{requestedDate}</strong>. Please note reschedules are not
                                    guaranteed and are dependent on teacher availability.
                                </h2>
                            </div>
                            <p onClick={() => backToTwo()} className='back-button'>Go Back</p>
                        </div>

                        {/* Step 4 */}
                        <div
                            style={steps === 4 ? { display: 'flex' } : { display: 'none' }}
                            className='top-container'
                        >
                            <div className='left'>
                                <h1>Confirm your Reschedule.</h1>
                                <h2>
                                    Confirm your reschedule for <strong>{requestedDate}</strong>.
                                </h2>
                            </div>
                            <p onClick={() => backToThree()} className='back-button'>Go Back</p>
                        </div>

                        {/* Step 1: Show class times to reschedule */}
                        <div
                            style={steps === 1 ? { display: 'flex' } : { display: 'none' }}
                            className='classtimes'
                        >
                            {classtimes?.map((classtime) => (
                                <div className='classtime-card' key={classtime}>
                                    <p>{formatDateToClassString(classtime)}</p>
                                    <button onClick={() => stepTwo(classtime)}>
                                        Reschedule This Class
                                    </button>
                                </div>
                            ))}
                        </div>

                        {/* Step 2: Show next 30 days */}
                        <div
                            style={steps === 2 ? { display: 'flex' } : { display: 'none' }}
                            className='classtimes'
                        >
                            <div className='day-card-container'>
                                {nextThirty?.map((day) => (
                                    <div className='day-card' key={day[0]}>
                                        <p className='date'>{day[1]}</p>
                                        <button onClick={() => stepThree(day)}>
                                            Check Teacher's Availability
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Step 3: Show teacher's availability for chosen day */}
                        <div
                            style={steps === 3 ? { display: 'flex' } : { display: 'none' }}
                            className='classtimes'
                        >
                            <div className='timeslot-container'>
                                {Object.keys(requestedDateAvailability).length === 0 ? (
                                    <div className='notimeslot-container'>
                                        <p>Your teacher does not have any availability for this day.</p>
                                    </div>
                                ) : (
                                    Object.entries(requestedDateAvailability).map(([timeSlot], index) => (
                                        <div className='timeslot-card' key={index}>
                                            <p>{timeSlot}</p>
                                            <button onClick={() => stepFour(timeSlot)}>
                                                Book Timeslot
                                            </button>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>

                        {/* Step 4: Confirmation */}
                        <div
                            style={steps === 4 ? { display: 'flex' } : { display: 'none' }}
                            className='classtimes'
                        >
                            <div className='confirmation-form'>
                                <div className='form-container'>
                                    <p>Canceled Class</p>
                                    <div className='input-container'>
                                        <input disabled value={canceledStudentDate} />
                                        <input disabled value={canceledStudentTime} />
                                    </div>
                                </div>

                                <div className='form-container'>
                                    <p>Time of Reschedule</p>
                                    <div className='input-container'>
                                        <input disabled value={rescheduledStudentDate} />
                                        <input disabled value={rescheduledStudentTime} />
                                    </div>
                                </div>

                                {/* If you want to debug teacher date/time, you can show them here:
                                <div className='form-container'>
                                  <p>Teacher sees it as</p>
                                  <div className='input-container'>
                                    <input disabled value={rescheduledTeacherDate} />
                                    <input disabled value={rescheduledTeacherTime} />
                                  </div>
                                </div> 
                                */}
                                
                                <button onClick={submitReschedule}>Confirm</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Reschedule;
