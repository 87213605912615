import React, { useEffect, useState } from 'react';
import Video from '../../components/video/video';
import CodeCard from '../../components/codecard/codecard';
import CopyBox from '../../components/copybox/copybox';
import Indent from '../../components/indent/indent';
import api from "../../api/api";
import Loader from '../../components/loader/loader';

const PowersScript = () => {
  return (
    <CodeCard title={"Powers Script"}>
      <span class="keyword">local</span> <span class="identifier">powers</span> <span class="operator">=</span> <span class="punctuation">&#123;</span><br />
      <Indent />
      <span class="identifier">superSpeed</span> <span class="operator">=</span> <span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="number">250</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">boolValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Super Speed"</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><span class="keyword">end</span><span class="punctuation">,</span><br />
      <Indent />
      <span class="identifier">superJump</span> <span class="operator">=</span> <span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">JumpPower</span> <span class="operator">=</span> <span class="number">200</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">boolValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Super Jump"</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><span class="keyword">end</span><span class="punctuation">,</span><br />
      <Indent />
      <span class="identifier">flying</span> <span class="operator">=</span> <span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">:</span><span class="function">ChangeState</span><span class="punctuation">(</span><span class="identifier">Enum</span><span class="operator">.</span><span class="property">HumanoidStateType</span><span class="operator">.</span><span class="property">Flying</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">boolValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Flight"</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><span class="keyword">end</span><span class="punctuation">,</span><br />
      <Indent />
      <span class="identifier">laserEyes</span> <span class="operator">=</span> <span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">boolValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Lasers"</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><span class="keyword">end</span><span class="punctuation">,</span><br />
      <Indent />
      <span class="identifier">teleportation</span> <span class="operator">=</span> <span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">boolValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Teleportation"</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent /><span class="identifier">boolValue</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="punctuation">&#125;</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">powerKeys</span> <span class="operator">=</span> <span class="punctuation">&#123;</span><span class="punctuation">&#125;</span><br />
      <br />
      <span class="keyword">for</span> <span class="punctuation">key,</span> <span class="punctuation">_</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">powers</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><span class="builtin">table</span><span class="operator">.</span><span class="function">insert</span><span class="punctuation">(</span><span class="identifier">powerKeys</span><span class="punctuation">,</span> <span class="identifier">key</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">removeExistingPowers</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">powerNames</span> <span class="operator">=</span> <span class="punctuation">&#123;</span><span class="string">"Super Speed"</span><span class="punctuation">,</span> <span class="string">"Super Jump"</span><span class="punctuation">,</span> <span class="string">"Flight"</span><span class="punctuation">,</span> <span class="string">"Lasers"</span><span class="punctuation">,</span> <span class="string">"Teleportation"</span><span class="punctuation">&#125;</span><br />
      <br />
      <Indent /><span class="keyword">for</span> <span class="punctuation">_,</span> <span class="identifier">powerName</span> <span class="keyword">in</span> <span class="punctuation">(</span><span class="identifier">powerNames</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">existingPower</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">powerName</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">existingPower</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">existingPower</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">PlayerAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">character</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">Died</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">()</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">character</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="number">16</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">character</span><span class="operator">.</span><span class="property">Humanoid</span><span class="operator">.</span><span class="property">JumpPower</span> <span class="operator">=</span> <span class="number">50</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="function">removeExistingPowers</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">index</span> <span class="operator">=</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">random</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">,</span> <span class="operator">#</span><span class="identifier">powerKeys</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">powerKey</span> <span class="operator">=</span> <span class="identifier">powerKeys</span><span class="punctuation">[</span><span class="identifier">index</span><span class="punctuation">]</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">powerFunc</span> <span class="operator">=</span> <span class="identifier">powers</span><span class="punctuation">[</span><span class="identifier">powerKey</span><span class="punctuation">]</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">repeat</span> <span class="builtin">wait</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="keyword">until</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span> <span class="operator">~=</span> <span class="keyword">nil</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><span class="function">removeExistingPowers</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">powerFunc</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const ActivateTeleport = () => {
  return (
    <CodeCard title={"Activate Teleport Script"}>
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">allPlayers</span> <span class="operator">=</span> <span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">UserInputService</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"UserInputService"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">teleportEvent</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"TeleportEvent"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onSpacePressed</span><span class="punctuation">(</span><span class="identifier">input</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">Space</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">for</span> <span class="punctuation">i,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">allPlayers</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">teleportation</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Teleportation"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">teleportation</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">==</span> <span class="boolean">true</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">mouse</span> <span class="operator">=</span> <span class="identifier">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><span class="operator">:</span><span class="function">GetMouse</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">mouse</span><span class="operator">.</span><span class="property">Target</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">position</span> <span class="operator">=</span> <span class="identifier">mouse</span><span class="operator">.</span><span class="property">Target</span><span class="operator">.</span><span class="property">Position</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="identifier">teleportEvent</span><span class="operator">:</span><span class="function">FireServer</span><span class="punctuation">(</span><span class="identifier">position</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">UserInputService</span><span class="operator">.</span><span class="property">InputBegan</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onSpacePressed</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const TeleportPlayer = () => {
  return (
    <CodeCard title={"Teleport Player Script"}>
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">teleportEvent</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"TeleportEvent"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">teleportPlayer</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">,</span> <span class="identifier">position</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">.</span><span class="property">HumanoidRootPart</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">CFrame</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">position</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">teleportEvent</span><span class="operator">.</span><span class="property">OnServerEvent</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">teleportPlayer</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const ActivateLaser = () => {
  return (
    <CodeCard title={"Activate Laser Script"}>
      <span class="keyword">local</span> <span class="identifier">UserInputService</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"UserInputService"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><br />
      <span class="keyword">local</span> <span class="identifier">mouse</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">GetMouse</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">laserEvent</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"LaserEvent"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">shootLaser</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">lasers</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Lasers"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">lasers</span><span class="operator">.</span><span class="property">Value</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">laserEvent</span><span class="operator">:</span><span class="function">FireServer</span><span class="punctuation">(</span><span class="identifier">mouse</span><span class="operator">.</span><span class="property">Hit</span><span class="operator">.</span><span class="property">p</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">mouse</span><span class="operator">.</span><span class="property">Button1Up</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">shootLaser</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const ShootLaser = () => {
  return (
    <CodeCard title={"Shoot Laser Script"}>
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">laserEvent</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"LaserEvent"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onShootLaser</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">,</span> <span class="identifier">targetPosition</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">character</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">head</span> <span class="operator">=</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Head"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">head</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">laser</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Part"</span><span class="punctuation">,</span> <span class="identifier">workspace</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">laser</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright red"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">laser</span><span class="operator">.</span><span class="property">Material</span> <span class="operator">=</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">Material</span><span class="operator">.</span><span class="property">Neon</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">laser</span><span class="operator">.</span><span class="property">Anchored</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">laser</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">laser</span><span class="operator">.</span><span class="property">Size</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0.2</span><span class="punctuation">,</span> <span class="number">0.2</span><span class="punctuation">,</span> <span class="number">20</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">laser</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">CFrame</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">head</span><span class="operator">.</span><span class="property">Position</span><span class="punctuation">,</span> <span class="identifier">targetPosition</span><span class="punctuation">)</span> <span class="operator">*</span> <span class="builtin">CFrame</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="operator">-</span><span class="identifier">laser</span><span class="operator">.</span><span class="property">Size</span><span class="operator">.</span><span class="property">Z</span> <span class="operator">/</span> <span class="number">2</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Debris"</span><span class="punctuation">)</span><span class="operator">:</span><span class="function">AddItem</span><span class="punctuation">(</span><span class="identifier">laser</span><span class="punctuation">,</span> <span class="number">0.2</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">function</span> <span class="function">onLaserTouch</span><span class="punctuation">(</span><span class="identifier">otherPart</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">otherCharacter</span> <span class="operator">=</span> <span class="identifier">otherPart</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">otherHumanoid</span> <span class="operator">=</span> <span class="identifier">otherCharacter</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">otherPlayer</span> <span class="operator">=</span> <span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">otherCharacter</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">otherHumanoid</span> <span class="keyword">and</span> <span class="identifier">otherPlayer</span> <span class="operator">~=</span> <span class="identifier">player</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="identifier">otherHumanoid</span><span class="operator">:</span><span class="function">TakeDamage</span><span class="punctuation">(</span><span class="number">10</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">+</span> <span class="number">1</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="identifier">laser</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onLaserTouch</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">laserEvent</span><span class="operator">.</span><span class="property">OnServerEvent</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onShootLaser</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const PlayerFlyScript = () => {
  return (
    <CodeCard title={"Player Fly Script"}>
      <span class="keyword">local</span> <span class="identifier">UserInputService</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"UserInputService"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">flySpeed</span> <span class="operator">=</span> <span class="number">100</span><br />
      <span class="keyword">local</span> <span class="identifier">flying</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><br />
      <span class="keyword">local</span> <span class="identifier">camera</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">CurrentCamera</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">bodyVelocity</span><br />
      <span class="keyword">local</span> <span class="identifier">bodyGyro</span><br />
      <span class="keyword">local</span> <span class="identifier">onInputConnection</span><br />
      <span class="keyword">local</span> <span class="identifier">onInputEndConnection</span><br />
      <br />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Wait</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">startFly</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">flying</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">return</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="identifier">flying</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">PlatformStand</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">primaryPart</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">.</span><span class="property">PrimaryPart</span><br />
      <Indent /><span class="identifier">bodyVelocity</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BodyVelocity"</span><span class="punctuation">,</span> <span class="identifier">primaryPart</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">bodyVelocity</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">bodyVelocity</span><span class="operator">.</span><span class="property">MaxForce</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">math</span><span class="operator">.</span><span class="function">huge</span><span class="punctuation">,</span> <span class="identifier">math</span><span class="operator">.</span><span class="function">huge</span><span class="punctuation">,</span> <span class="identifier">math</span><span class="operator">.</span><span class="function">huge</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="identifier">bodyGyro</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"BodyGyro"</span><span class="punctuation">,</span> <span class="identifier">primaryPart</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">bodyGyro</span><span class="operator">.</span><span class="property">MaxTorque</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="identifier">math</span><span class="operator">.</span><span class="function">huge</span><span class="punctuation">,</span> <span class="identifier">math</span><span class="operator">.</span><span class="function">huge</span><span class="punctuation">,</span> <span class="identifier">math</span><span class="operator">.</span><span class="function">huge</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">bodyGyro</span><span class="operator">.</span><span class="property">P</span> <span class="operator">=</span> <span class="number">10000</span><br />
      <Indent /><span class="identifier">bodyGyro</span><span class="operator">.</span><span class="property">D</span> <span class="operator">=</span> <span class="number">500</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">function</span> <span class="function">onInput</span><span class="punctuation">(</span><span class="identifier">input</span><span class="punctuation">,</span> <span class="identifier">isProcessed</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">flying</span> <span class="keyword">and</span> <span class="keyword">not</span> <span class="identifier">isProcessed</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">direction</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">horizontalDirection</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">D</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">direction</span> <span class="operator">=</span> <span class="identifier">direction</span> <span class="operator">+</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">RightVector</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">horizontalDirection</span> <span class="operator">=</span> <span class="identifier">horizontalDirection</span> <span class="operator">+</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">RightVector</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">elseif</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">A</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">direction</span> <span class="operator">=</span> <span class="identifier">direction</span> <span class="operator">-</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">RightVector</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">horizontalDirection</span> <span class="operator">=</span> <span class="identifier">horizontalDirection</span> <span class="operator">-</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">RightVector</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">W</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">direction</span> <span class="operator">=</span> <span class="identifier">direction</span> <span class="operator">+</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">LookVector</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">horizontalDirection</span> <span class="operator">=</span> <span class="identifier">horizontalDirection</span> <span class="operator">+</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">LookVector</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">elseif</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">S</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">direction</span> <span class="operator">=</span> <span class="identifier">direction</span> <span class="operator">-</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">LookVector</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">horizontalDirection</span> <span class="operator">=</span> <span class="identifier">horizontalDirection</span> <span class="operator">-</span> <span class="identifier">camera</span><span class="operator">.</span><span class="property">CFrame</span><span class="operator">.</span><span class="property">LookVector</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">Space</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">direction</span> <span class="operator">=</span> <span class="identifier">direction</span> <span class="operator">+</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">1</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">elseif</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">LeftShift</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">direction</span> <span class="operator">=</span> <span class="identifier">direction</span> <span class="operator">-</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">1</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">direction</span><span class="operator">.</span><span class="property">magnitude</span> <span class="operator">&gt;</span> <span class="number">0</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">bodyVelocity</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="identifier">direction</span><span class="operator">.</span><span class="property">unit</span> <span class="operator">*</span> <span class="identifier">flySpeed</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent /><span class="comment">-- Only adjust player orientation if there's horizontal movement</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">horizontalDirection</span><span class="operator">.</span><span class="property">magnitude</span> <span class="operator">&gt;</span> <span class="number">0</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="identifier">bodyGyro</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">CFrame</span><span class="punctuation">.</span><span class="function">lookAt</span><span class="punctuation">(</span><span class="identifier">primaryPart</span><span class="operator">.</span><span class="property">Position</span><span class="punctuation">,</span> <span class="identifier">primaryPart</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">+</span> <span class="identifier">horizontalDirection</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="identifier">onInputConnection</span> <span class="operator">=</span> <span class="identifier">UserInputService</span><span class="operator">.</span><span class="property">InputBegan</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onInput</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="identifier">onInputEndConnection</span> <span class="operator">=</span> <span class="identifier">UserInputService</span><span class="operator">.</span><span class="property">InputEnded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">input</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">flying</span> <span class="keyword">and</span> <span class="punctuation">(</span><span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">W</span> <span class="keyword">or</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">A</span> <span class="keyword">or</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">S</span> <span class="keyword">or</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">D</span> <span class="keyword">or</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">Space</span> <span class="keyword">or</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">LeftShift</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">bodyVelocity</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="punctuation">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">stopFly</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">flying</span> <span class="keyword">then</span> <span class="keyword">return</span> <span class="keyword">end</span><br />
      <Indent /><span class="identifier">flying</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">humanoid</span><span class="operator">.</span><span class="property">PlatformStand</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">bodyVelocity</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">bodyVelocity</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">bodyGyro</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">bodyGyro</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">onInputConnection</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">onInputConnection</span><span class="operator">:</span><span class="function">Disconnect</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">onInputEndConnection</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">onInputEndConnection</span><span class="operator">:</span><span class="function">Disconnect</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">toggleFly</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">flying</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="function">stopFly</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">else</span><br />
      <Indent /><Indent /><span class="function">startFly</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">UserInputService</span><span class="operator">.</span><span class="property">InputBegan</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">input</span><span class="punctuation">,</span> <span class="identifier">isProcessed</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">isProcessed</span> <span class="keyword">and</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="identifier">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="operator">.</span><span class="property">F</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Flight"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Flight</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">==</span> <span class="boolean">true</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="function">toggleFly</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const TextLabelScript = () => {
  return (
    <CodeCard title={"Text Label Script"}>
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><br />
      <span class="keyword">local</span> <span class="identifier">textLabel</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">powerNames</span> <span class="operator">=</span> <span class="punctuation">&#123;</span><span class="string">"Super Speed"</span><span class="punctuation">,</span> <span class="string">"Super Jump"</span><span class="punctuation">,</span> <span class="string">"Flight"</span><span class="punctuation">,</span> <span class="string">"Lasers"</span><span class="punctuation">,</span> <span class="string">"Teleportation"</span><span class="punctuation">&#125;</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">updatePowerDisplay</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">for</span> <span class="identifier">_</span><span class="punctuation">,</span> <span class="identifier">powerName</span> <span class="keyword">in</span> <span class="identifier">ipairs</span><span class="punctuation">(</span><span class="identifier">powerNames</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">power</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">powerName</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">power</span> <span class="keyword">and</span> <span class="identifier">power</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">power</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">==</span> <span class="boolean">true</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">textLabel</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="identifier">tostring</span><span class="punctuation">(</span><span class="string">"Your Power: "</span> <span class="operator">..</span> <span class="identifier">powerName</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">return</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="identifier">textLabel</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="string">"No Power"</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">ChildAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">child</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">child</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">table</span><span class="operator">.</span><span class="function">find</span><span class="punctuation">(</span><span class="identifier">powerNames</span><span class="punctuation">,</span> <span class="identifier">child</span><span class="operator">.</span><span class="property">Name</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="function">updatePowerDisplay</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><span class="punctuation">)</span><br />
      <br />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">ChildRemoved</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">child</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">child</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BoolValue"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">table</span><span class="operator">.</span><span class="function">find</span><span class="punctuation">(</span><span class="identifier">powerNames</span><span class="punctuation">,</span> <span class="identifier">child</span><span class="operator">.</span><span class="property">Name</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="function">updatePowerDisplay</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><span class="punctuation">)</span><br />
      <br />
      <span class="function">updatePowerDisplay</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const ZombieSpawnerScript = () => {
  return (
    <CodeCard title={"Zombie Spawner Script"}>
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">zombie</span> <span class="operator">=</span> <span class="identifier">ReplicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Drooling Zombie"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">spawnZombie</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">zombieClone</span> <span class="operator">=</span> <span class="identifier">zombie</span><span class="operator">:</span><span class="function">Clone</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="identifier">zombieClone</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">Clones</span><br />
      <Indent /><span class="identifier">zombieClone</span><span class="operator">.</span><span class="property">Torso</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">.</span><span class="property">CFrame</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">waitTime</span> <span class="operator">=</span> <span class="identifier">math</span><span class="operator">.</span><span class="function">random</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">,</span> <span class="number">4</span><span class="punctuation">)</span><br />
      <Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="identifier">waitTime</span><span class="punctuation">)</span><br />
      <Indent /><span class="function">spawnZombie</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}

const LeaderboardScript = () => {
  return (
    <CodeCard title={"Leaderboard Script"}>
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onPlayerAdded</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">leaderstats</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Folder"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"leaderstats"</span><br />
      <Indent /><Indent /><span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">points</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Points"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">points</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">points</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"IntValue"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">points</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Points"</span><br />
      <Indent /><Indent /><span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><Indent /><span class="identifier">points</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">Players</span><span class="operator">.</span><span class="property">PlayerAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onPlayerAdded</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const BlastZombiesScriptOne = () => {
  return (
    <CodeCard title={"Blast Zombies Script Part One"}>
      <span class="keyword">function</span> <span class="identifier">GivePoints</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">Character</span> <span class="operator">=</span> <span class="identifier">Tool</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">Player</span> <span class="operator">=</span> <span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">Character</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">Player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">points</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Points"</span><span class="punctuation">)</span><br />
      <Indent /><span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">+</span> <span class="number">1</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}

const BlastZombiesScriptTwo = () => {
  return (
    <CodeCard title={"Blast Zombies Script Part Two"}>
      <span class="function">GivePoints</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const DispenserScript = () => {
  return (
    <CodeCard title={"Dispenser Script"}>
      <span class="keyword">function</span> <span class="identifier">dispenseGun</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">lasergun</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">.</span><span class="property">Folder</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">[</span><span class="number">1</span><span class="punctuation">]</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="keyword">not</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Backpack</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">lasergun</span><span class="operator">.</span><span class="property">Name</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="keyword">not</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">lasergun</span><span class="operator">.</span><span class="property">Name</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">clone</span> <span class="operator">=</span> <span class="identifier">lasergun</span><span class="operator">:</span><span class="function">Clone</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">clone</span><span class="operator">.</span><span class="property">Grab</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Handle"</span><br />
      <Indent /><Indent /><span class="identifier">clone</span><span class="operator">.</span><span class="property">Handle</span><span class="operator">.</span><span class="property">Anchored</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><Indent /><span class="identifier">clone</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Backpack</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">.</span><span class="property">case</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">dispenseGun</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const TimerScript = () => {
  return (
    <CodeCard title={"Timer Script"}>
      <span class="keyword">local</span> <span class="identifier">replicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">countdownValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"StringValue"</span><span class="punctuation">,</span> <span class="identifier">replicatedStorage</span><span class="punctuation">)</span><br />
      <span class="identifier">countdownValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Countdown"</span><br />
      <span class="identifier">countdownValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="string">"600"</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">resetGame</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">for</span> <span class="punctuation">_,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Points"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">:</span><span class="function">LoadCharacter</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="function">startCountdown</span><span class="punctuation">(</span><span class="number">60</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">determineWinner</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">highestScore</span> <span class="operator">=</span> <span class="number">-1</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">winner</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <br />
      <Indent /><span class="keyword">for</span> <span class="punctuation">_,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Points"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">score</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Points</span><span class="operator">.</span><span class="property">Value</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="identifier">score</span> <span class="operator">&gt;</span> <span class="identifier">highestScore</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">highestScore</span> <span class="operator">=</span> <span class="identifier">score</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">winner</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">winner</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">countdownValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">tostring</span><span class="punctuation">(</span><span class="string">"Winner: "</span> <span class="operator">..</span> <span class="identifier">winner</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">..</span> <span class="string">" with "</span> <span class="operator">..</span> <span class="identifier">highestScore</span> <span class="operator">..</span> <span class="string">" points!"</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">else</span><br />
      <Indent /><Indent /><span class="identifier">countdownValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">tostring</span><span class="punctuation">(</span><span class="string">"No winner."</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">5</span><span class="punctuation">)</span><br />
      <Indent /><span class="function">resetGame</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">startCountdown</span><span class="punctuation">(</span><span class="identifier">duration</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">startTime</span> <span class="operator">=</span> <span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">endTime</span> <span class="operator">=</span> <span class="identifier">startTime</span> <span class="operator">+</span> <span class="identifier">duration</span><br />
      <br />
      <Indent /><span class="keyword">while</span> <span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="operator">&lt;</span> <span class="identifier">endTime</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">remainingTime</span> <span class="operator">=</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">max</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">floor</span><span class="punctuation">(</span><span class="identifier">endTime</span> <span class="operator">-</span> <span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="identifier">countdownValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">tostring</span><span class="punctuation">(</span><span class="identifier">remainingTime</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="number">0.1</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">end</span><br />
      <br />
      <Indent /><span class="function">determineWinner</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="function">startCountdown</span><span class="punctuation">(</span><span class="number">60</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}


const TimerLocalScript = () => {
  return (
    <CodeCard title={"Timer Local Script"}>
      <span class="keyword">local</span> <span class="identifier">replicatedStorage</span> <span class="operator">=</span> <span class="identifier">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">countdownValue</span> <span class="operator">=</span> <span class="identifier">replicatedStorage</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Countdown"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">textLabel</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="identifier">countdownValue</span><span class="operator">.</span><span class="property">Changed</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">newValue</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">tonumber</span><span class="punctuation">(</span><span class="identifier">newValue</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">textLabel</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="string">"Time Remaining: "</span> <span class="operator">..</span> <span class="identifier">newValue</span><br />
      <Indent /><span class="keyword">else</span><br />
      <Indent /><Indent /><span class="identifier">textLabel</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="identifier">newValue</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}


const SaveTheCity = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectVideo, setProjectVideo] = useState('');
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchVideoUrls = async (fileNames) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/videos`, { fileNames });
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching video URLs:', error.response?.data || error.message);
      return [];
    }
  };

  useEffect(() => {
    const fileNames = [
      'savethecity/SAVETHECITY01.mp4', 
      'savethecity/SAVETHECITY02.mp4',
      'savethecity/SAVETHECITY03.mp4',
      'savethecity/SAVETHECITY04.mp4',
      'savethecity/SAVETHECITY05.mp4',
      'savethecity/SAVETHECITY06.mp4',
      'savethecity/SAVETHECITY07.mp4'
    ];

    fetchVideoUrls(fileNames).then((urls) => {
      if (urls && Array.isArray(urls)) {
        setVideoUrls(urls);

        const dynamicTutorials = [
          ["Player Power Scripts", urls[0]?.url, <PowersScript />, <ActivateTeleport />, <TeleportPlayer />, <ActivateLaser />, <ShootLaser />, <PlayerFlyScript />],
          ["Build the Power GUI", urls[1]?.url, <TextLabelScript />, <CopyBox title="Zombie Town" text={"https://create.roblox.com/store/asset/86538539401119/Zombie-Town"} />],
          ["Zombie Spawn System", urls[2]?.url, <ZombieSpawnerScript />],
          ["Laser Gun Fun", urls[3]?.url, <CopyBox title="Laser Gun" text={"https://create.roblox.com/store/asset/111585286667421/laser-Gun"} />, <LeaderboardScript />, <BlastZombiesScriptOne />, <BlastZombiesScriptTwo />],
          ["More Zombies, More Points", urls[4]?.url],
          ["Laser guns for everyone", urls[5]?.url, <DispenserScript />],
          ["Let the countdown begin!", urls[6]?.url, <TimerScript />, <TimerLocalScript />]
        ];

        setTutorials(dynamicTutorials);
        setProjectName(dynamicTutorials[0][0]); 
        setProjectVideo(dynamicTutorials[0][1]);
      }
      setLoading(false); 
    });
  }, []);

  const changeVideo = (tutorial, index) => {
    setProjectName(tutorial[0]);
    setProjectVideo(tutorial[1]);
    setTutorialIndex(index);
  };

  return (
    <div className='roblox-tutorial'>

      {
        loading === true ?
        <div className='loadcontainer'>
          <Loader />
        </div>
        :
        <div className='tutorial-structure'>
          <div className='tutorial-structure-left'>
            <Video title={projectName} vidfile={projectVideo} />
            
            {tutorials[tutorialIndex]?.length >= 3 &&
              tutorials[tutorialIndex]
                .slice(2) // Extracts all elements starting from index 2
                .map((tutorial, index) => (
                <div key={index}>{tutorial}</div> // Replace with your desired rendering logic
            ))}
          </div>

          <div className='tutorial-structure-right'>
            <div className='project-name'>
              <h3>Save The City</h3>
            </div>

            {tutorials.map((tutorial, index) => (
              <div
                key={index}
                onClick={() => changeVideo(tutorial, index)}
                className={`tutorial-card ${tutorialIndex === index ? 'selected' : ''}`}
              >
                <div className='tutorial-card-left'>
                  <p className='index'>{index + 1}.</p>
                </div>

                <div className='tutorial-card-right'>
                  <video
                    className='video-preview'
                    preload="metadata" // stops it from downloading full video
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source src={tutorial[1]} type="video/mp4" />
                    Failed to load video tag.
                  </video>

                  <p className='tutorial-name'>{tutorial[0]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      }

    </div>
  )
}

export default SaveTheCity