import React, { useEffect, useState } from 'react';
import Video from '../../components/video/video';
import CodeCard from '../../components/codecard/codecard';
import CopyBox from '../../components/copybox/copybox';
import Indent from '../../components/indent/indent';
import api from "../../api/api";
import Loader from '../../components/loader/loader';

const SpinningBeamScript = () => {
  return (
    <CodeCard title={"Spinning Beam Script"} >
      <span class="keyword">local</span> <span class="identifier">beam</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">rotationSpeed</span> <span class="operator">=</span> <span class="number">5</span><br />
      <br />
      <span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><span class="identifier">beam</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="identifier">beam</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">*</span> <span class="builtin">CFrame</span><span class="operator">.</span><span class="function">Angles</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">rad</span><span class="punctuation">(</span><span class="identifier">rotationSpeed</span><span class="punctuation">)</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br /><br/>
      <Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}

const MovingPlatformScript = () => {
  return (
    <CodeCard title={"Moving Platform Script"} >
      <span class="keyword">local</span> <span class="identifier">platform</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">speed</span> <span class="operator">=</span> <span class="number">10</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">startPoint</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">78.5</span><span class="punctuation">,</span> <span class="number">6.5</span><span class="punctuation">,</span> <span class="number">34.5</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">endPoint</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">143.5</span><span class="punctuation">,</span> <span class="number">6.5</span><span class="punctuation">,</span> <span class="number">34.5</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">movePlatform</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">startPosition</span> <span class="operator">=</span> <span class="identifier">startPoint</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">endPosition</span> <span class="operator">=</span> <span class="identifier">endPoint</span><br />
      <br />
      <Indent /><span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">distance</span> <span class="operator">=</span> <span class="punctuation">(</span><span class="identifier">endPosition</span> <span class="operator">-</span> <span class="identifier">startPosition</span><span class="punctuation">)</span><span class="operator">.</span><span class="property">Magnitude</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">duration</span> <span class="operator">=</span> <span class="identifier">distance</span> <span class="operator">/</span> <span class="identifier">speed</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">startTime</span> <span class="operator">=</span> <span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="keyword">while</span> <span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="operator">-</span> <span class="identifier">startTime</span> <span class="operator">&lt;</span> <span class="identifier">duration</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">progress</span> <span class="operator">=</span> <span class="punctuation">(</span><span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="operator">-</span> <span class="identifier">startTime</span><span class="punctuation">)</span> <span class="operator">/</span> <span class="identifier">duration</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">platform</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">=</span> <span class="identifier">startPosition</span><span class="operator">:</span><span class="function">Lerp</span><span class="punctuation">(</span><span class="identifier">endPosition</span><span class="punctuation">,</span> <span class="identifier">progress</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="identifier">platform</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">=</span> <span class="identifier">endPosition</span><br />
      <Indent /><Indent /><span class="identifier">startPosition</span><span class="punctuation">,</span> <span class="identifier">endPosition</span> <span class="operator">=</span> <span class="identifier">endPosition</span><span class="punctuation">,</span> <span class="identifier">startPosition</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">platform</span><span class="operator">.</span><span class="property">Anchored</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <span class="function">movePlatform</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const CheckpointScript = () => {
  return (
    <CodeCard title={"Checkpoint Script"}>
      <span class="keyword">local</span> <span class="identifier">playerService</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">storageService</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ServerStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">checkpointMarker</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">handleCheckpoint</span><span class="punctuation">(</span><span class="identifier">hitObject</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">hitObject</span> <span class="keyword">and</span> <span class="identifier">hitObject</span><span class="operator">.</span><span class="property">Parent</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">hitObject</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">:</span><span class="function">FindFirstChildOfClass</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="keyword">if</span> <span class="identifier">humanoid</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="identifier">playerService</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">hitObject</span><span class="operator">.</span><span class="property">Parent</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">checkpointsFolder</span> <span class="operator">=</span> <span class="identifier">storageService</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"PlayerCheckpoints"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">checkpointsFolder</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">checkpointsFolder</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Folder"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">checkpointsFolder</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"PlayerCheckpoints"</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">checkpointsFolder</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">storageService</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">playerCheckpointId</span> <span class="operator">=</span> <span class="string">"Checkpoint_"</span> <span class="operator">..</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">UserId</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">playerCheckpoint</span> <span class="operator">=</span> <span class="identifier">checkpointsFolder</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="identifier">playerCheckpointId</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><br />
      <Indent /><Indent /><Indent /><span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">playerCheckpoint</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">playerCheckpoint</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"ObjectValue"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">playerCheckpoint</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="identifier">playerCheckpointId</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">playerCheckpoint</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">checkpointsFolder</span><br />
      <Indent /><Indent /><Indent /><Indent /><br />
      <Indent /><Indent /><Indent /><Indent /><span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="keyword">function</span><span class="punctuation">(</span><span class="identifier">newCharacter</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">savedCheckpoint</span> <span class="operator">=</span> <span class="identifier">checkpointsFolder</span><span class="punctuation">[</span><span class="identifier">playerCheckpointId</span><span class="punctuation">]</span><span class="operator">.</span><span class="property">Value</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><span class="identifier">newCharacter</span><span class="operator">:</span><span class="function">SetPrimaryPartCFrame</span><span class="punctuation">(</span>
      <span class="builtin">CFrame</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="builtin">math</span><span class="operator">.</span><span class="function">random</span><span class="punctuation">(</span><span class="number">-4</span><span class="punctuation">,</span> <span class="number">4</span><span class="punctuation">)</span><span class="punctuation">,</span> <span class="number">4</span><span class="punctuation">,</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">random</span><span class="punctuation">(</span><span class="number">-4</span><span class="punctuation">,</span> <span class="number">4</span><span class="punctuation">)</span><span class="punctuation">)</span><span class="punctuation">)</span>
      <span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><span class="keyword">end</span><span class="punctuation">)</span><br /><br />
      <Indent /><Indent /><Indent /><span class="keyword">end</span><br /><br/>
      <Indent /><Indent /><Indent /><span class="identifier">playerCheckpoint</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">checkpointMarker</span><br /><br/>
          
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">checkpointMarker</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">handleCheckpoint</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const DuplicatePlatformScript = () => {
  return (
    <CodeCard title={"2nd Moving Platform Script"}>
      <span class="keyword">local</span> <span class="identifier">platform</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">speed</span> <span class="operator">=</span> <span class="number">10</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">startPoint</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">186</span><span class="punctuation">,</span> <span class="number">6.5</span><span class="punctuation">,</span> <span class="number">34.5</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">endPoint</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">236.5</span><span class="punctuation">,</span> <span class="number">6.5</span><span class="punctuation">,</span> <span class="number">34.5</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">movePlatform</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">startPosition</span> <span class="operator">=</span> <span class="identifier">startPoint</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">endPosition</span> <span class="operator">=</span> <span class="identifier">endPoint</span><br />
      <br />
      <Indent /><span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">distance</span> <span class="operator">=</span> <span class="punctuation">(</span><span class="identifier">endPosition</span> <span class="operator">-</span> <span class="identifier">startPosition</span><span class="punctuation">)</span><span class="operator">.</span><span class="property">Magnitude</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">duration</span> <span class="operator">=</span> <span class="identifier">distance</span> <span class="operator">/</span> <span class="identifier">speed</span><br />
      <Indent /><Indent /><span class="keyword">local</span> <span class="identifier">startTime</span> <span class="operator">=</span> <span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="keyword">while</span> <span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="operator">-</span> <span class="identifier">startTime</span> <span class="operator">&lt;</span> <span class="identifier">duration</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><Indent /><span class="keyword">local</span> <span class="identifier">progress</span> <span class="operator">=</span> <span class="punctuation">(</span><span class="builtin">tick</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="operator">-</span> <span class="identifier">startTime</span><span class="punctuation">)</span> <span class="operator">/</span> <span class="identifier">duration</span><br />
      <Indent /><Indent /><Indent /><span class="identifier">platform</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">=</span> <span class="identifier">startPosition</span><span class="operator">:</span><span class="function">Lerp</span><span class="punctuation">(</span><span class="identifier">endPosition</span><span class="punctuation">,</span> <span class="identifier">progress</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><span class="keyword">end</span><br />
      <Indent /><Indent /><br />
      <Indent /><Indent /><span class="identifier">platform</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">=</span> <span class="identifier">endPosition</span><br />
      <Indent /><Indent /><span class="identifier">startPosition</span><span class="punctuation">,</span> <span class="identifier">endPosition</span> <span class="operator">=</span> <span class="identifier">endPosition</span><span class="punctuation">,</span> <span class="identifier">startPosition</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">platform</span><span class="operator">.</span><span class="property">Anchored</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <span class="function">movePlatform</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const SpinningLaserScript = () => {
  return (
    <CodeCard title={"Spinning Laser Script"}>
      <span class="keyword">local</span> <span class="identifier">beam</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">rotationSpeed</span> <span class="operator">=</span> <span class="number">5</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">whenTouched</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">human</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="keyword">if</span> <span class="identifier">human</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">human</span><span class="operator">.</span><span class="property">Health</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">while</span> <span class="boolean">true</span> <span class="keyword">do</span><br />
      <Indent /><span class="identifier">beam</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="identifier">beam</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">*</span> <span class="builtin">CFrame</span><span class="operator">.</span><span class="function">Angles</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="builtin">math</span><span class="operator">.</span><span class="function">rad</span><span class="punctuation">(</span><span class="identifier">rotationSpeed</span><span class="punctuation">)</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="identifier">beam</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">whenTouched</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><span class="builtin">wait</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}

const LavaScript = () => {
  return (
    <CodeCard title={"Lava Script"}>
      <span class="keyword">local</span> <span class="identifier">lavaBlock</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">whenTouched</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">human</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">human</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">human</span><span class="operator">.</span><span class="property">Health</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">lavaBlock</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">whenTouched</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const LaserScript = () => {
  return (
    <CodeCard title={"Laser Script"}>
      <span class="keyword">local</span> <span class="identifier">laser</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">whenTouched</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent /><span class="keyword">local</span> <span class="identifier">human</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <Indent /><br />
      <Indent /><span class="keyword">if</span> <span class="identifier">human</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><span class="identifier">human</span><span class="operator">.</span><span class="property">Health</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">laser</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">whenTouched</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const Jumpquest = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectVideo, setProjectVideo] = useState('');
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchVideoUrls = async (fileNames) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/videos`, { fileNames });
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching video URLs:', error.response?.data || error.message);
      return [];
    }
  };

  useEffect(() => {
    const fileNames = [
      'jumpquest/JUMPQUEST01.mp4', 
      'jumpquest/JUMPQUEST02.mp4',
      'jumpquest/JUMPQUEST03.mp4',
      'jumpquest/JUMPQUEST04.mp4',
      'jumpquest/JUMPQUEST05.mp4',
      'jumpquest/JUMPQUEST06.mp4',
      'jumpquest/JUMPQUEST07.mp4',
      'jumpquest/JUMPQUEST08.mp4',
      'jumpquest/JUMPQUEST09.mp4',
      'jumpquest/JUMPQUEST010.mp4',
      'jumpquest/JUMPQUEST011.mp4',
      'jumpquest/JUMPQUEST012.mp4',
      'jumpquest/JUMPQUEST013.mp4',
    ];

    fetchVideoUrls(fileNames).then((urls) => {
      if (urls && Array.isArray(urls)) {
        setVideoUrls(urls);

        const dynamicTutorials = [
          ["Designing the First Obstacle", urls[0]?.url],
          ["The Path of Plastic", urls[1]?.url],
          ["The Ghost Block", urls[2]?.url],
          ["Trampoline Park", urls[3]?.url],
          ["Don't get hit", urls[4]?.url, <SpinningBeamScript />],
          ["Moving Platform for player transportation", urls[5]?.url, <MovingPlatformScript />],
          ["Reach the checkpoint!", urls[6]?.url, <CheckpointScript />, <CopyBox title="Checkered Flag" text={"https://create.roblox.com/store/asset/88767176647641/CheckeredFlag"} />],
          ["Learning to duplicate", urls[7]?.url, <DuplicatePlatformScript />],
          ["Avoid the Spinning Lasers", urls[8]?.url, <SpinningLaserScript />],
          ["Tightrope time!", urls[9]?.url],
          ["Don't touch the lava...", urls[10]?.url, <LavaScript />],
          ["The Treadmill of Doom", urls[11]?.url, <LaserScript />],
          ["Create a castle hangout", urls[12]?.url]
        ];

        setTutorials(dynamicTutorials);
        setProjectName(dynamicTutorials[0][0]); 
        setProjectVideo(dynamicTutorials[0][1]);
      }
      setLoading(false); 
    });
  }, []);

  const changeVideo = (tutorial, index) => {
    setProjectName(tutorial[0]);
    setProjectVideo(tutorial[1]);
    setTutorialIndex(index);
  };

  return (
    <div className='roblox-tutorial'>

      {
        loading === true ?
        <div className='loadcontainer'>
          <Loader />
        </div>
        :
        <div className='tutorial-structure'>
          <div className='tutorial-structure-left'>
            <Video title={projectName} vidfile={projectVideo} />
            
            {tutorials[tutorialIndex]?.length >= 3 &&
              tutorials[tutorialIndex]
                .slice(2) // Extracts all elements starting from index 2
                .map((tutorial, index) => (
                <div key={index}>{tutorial}</div> // Replace with your desired rendering logic
            ))}
          </div>

          <div className='tutorial-structure-right'>
            <div className='project-name'>
              <h3>Jumpquest</h3>
            </div>

            {tutorials.map((tutorial, index) => (
              <div
                key={index}
                onClick={() => changeVideo(tutorial, index)}
                className={`tutorial-card ${tutorialIndex === index ? 'selected' : ''}`}
              >
                <div className='tutorial-card-left'>
                  <p className='index'>{index + 1}.</p>
                </div>

                <div className='tutorial-card-right'>
                  <video
                    className='video-preview'
                    preload="metadata" // stops it from downloading full video
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source src={tutorial[1]} type="video/mp4" />
                    Failed to load video tag.
                  </video>

                  <p className='tutorial-name'>{tutorial[0]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      }

    </div>
  )
}

export default Jumpquest