import React from 'react';

const Indent = () => {
  return (
    <>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </>
  )
}

export default Indent;