import React from 'react';
import "./video.css";

const Video = ({ title, vidfile }) => {
  return (
    <div className='video-container'>
        <h2>{title}</h2>
        
        <video key={vidfile} className='video-tag' controls>
        <source src={vidfile} type="video/mp4" />
        Failed to load video tag.
        </video>
    </div>
  )
}

export default Video