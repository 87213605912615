import React, { useEffect, useState } from 'react';
import Video from '../../components/video/video';
import CodeCard from '../../components/codecard/codecard';
import Indent from '../../components/indent/indent';
import api from "../../api/api";
import Loader from '../../components/loader/loader';
import CopyBox from '../../components/copybox/copybox';

const GameSetupScript = () => {
  return (
    <CodeCard title={"Game Setup Script"}>
      <span class="keyword">local</span> <span class="identifier">Teams</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Teams"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">BlueTeam</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Team"</span><span class="punctuation">)</span><br />
      <span class="identifier">BlueTeam</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Blue"</span><br />
      <span class="identifier">BlueTeam</span><span class="operator">.</span><span class="property">TeamColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright blue"</span><span class="punctuation">)</span><br />
      <span class="identifier">BlueTeam</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">Teams</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">YellowTeam</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Team"</span><span class="punctuation">)</span><br />
      <span class="identifier">YellowTeam</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Yellow"</span><br />
      <span class="identifier">YellowTeam</span><span class="operator">.</span><span class="property">TeamColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"New Yeller"</span><span class="punctuation">)</span><br />
      <span class="identifier">YellowTeam</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">Teams</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">NeutralTeam</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Team"</span><span class="punctuation">)</span><br />
      <span class="identifier">NeutralTeam</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Neutral"</span><br />
      <span class="identifier">NeutralTeam</span><span class="operator">.</span><span class="property">TeamColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"White"</span><span class="punctuation">)</span><br />
      <span class="identifier">NeutralTeam</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">Teams</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onPlayerAdded</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Folder"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"leaderstats"</span><br />
      <Indent />
      <span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">TeamValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"StringValue"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">TeamValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Team"</span><br />
      <Indent />
      <span class="identifier">TeamValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">NeutralTeam</span><span class="operator">.</span><span class="property">Name</span><br />
      <Indent />
      <span class="identifier">TeamValue</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">ScoreValue</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"IntValue"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">ScoreValue</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Score"</span><br />
      <Indent />
      <span class="identifier">ScoreValue</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent />
      <span class="identifier">ScoreValue</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><br />
      <br />
      <Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">=</span> <span class="identifier">NeutralTeam</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">Players</span><span class="operator">.</span><span class="property">PlayerAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onPlayerAdded</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

// YellowTeamPickerScript
const YellowTeamPickerScript = () => {
  return (
    <CodeCard title={"Yellow Team Picker Script"}>
      <span class="keyword">local</span> <span class="identifier">Teams</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Teams"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">YellowTeam</span> <span class="operator">=</span> <span class="identifier">Teams</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Yellow"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">selector</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">teleportPosition</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">yellowSpawn</span><span class="operator">.</span><span class="property">CFrame</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">chooseYellowTeam</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">~=</span> <span class="identifier">YellowTeam</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">=</span> <span class="identifier">YellowTeam</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Team"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Team</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">YellowTeam</span><span class="operator">.</span><span class="property">Name</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">humanoidRootPart</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">humanoidRootPart</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">humanoidRootPart</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="identifier">teleportPosition</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">selector</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">chooseYellowTeam</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

// BlueTeamPickerScript
const BlueTeamPickerScript = () => {
  return (
    <CodeCard title={"Blue Team Picker Script"}>
      <span class="keyword">local</span> <span class="identifier">Teams</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Teams"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">BlueTeam</span> <span class="operator">=</span> <span class="identifier">Teams</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Blue"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">selector</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">teleportPosition</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">blueSpawn</span><span class="operator">.</span><span class="property">CFrame</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">chooseBlueTeam</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">~=</span> <span class="identifier">BlueTeam</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">=</span> <span class="identifier">BlueTeam</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Team"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">leaderstats</span><span class="operator">.</span><span class="property">Team</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">BlueTeam</span><span class="operator">.</span><span class="property">Name</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">humanoidRootPart</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"HumanoidRootPart"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">humanoidRootPart</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">humanoidRootPart</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="identifier">teleportPosition</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">selector</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">chooseBlueTeam</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

const SoccerBallScript = () => {
  return (
    <CodeCard title={"Soccer Ball Script"}>
      <span class="keyword">local</span> <span class="identifier">ball</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">kickBall</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">character</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Parent</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">humanoid</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">direction</span> <span class="operator">=</span> <span class="identifier">ball</span><span class="operator">.</span><span class="property">Position</span> <span class="operator">-</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Position</span><br />
      <Indent /><Indent />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="identifier">direction</span><span class="operator">.</span><span class="property">unit</span><span class="operator">*</span><span class="number">50</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">kickBall</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

// YellowGoalScript
const YellowGoalScript = () => {
  return (
    <CodeCard title={"Yellow Goal Script"}>
      <span class="keyword">local</span> <span class="identifier">Teams</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Teams"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">BlueTeam</span> <span class="operator">=</span> <span class="identifier">Teams</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Blue"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">NeutralTeam</span> <span class="operator">=</span> <span class="identifier">Teams</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Neutral"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">goalDetector</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">YellowScoreBlock</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">YellowScoreBlock</span><span class="operator">.</span><span class="property">SurfaceGui</span><span class="operator">.</span><span class="property">TextLabel</span><br />
      <span class="keyword">local</span> <span class="identifier">BlueScoreBlock</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">BlueScoreBlock</span><span class="operator">.</span><span class="property">SurfaceGui</span><span class="operator">.</span><span class="property">TextLabel</span><br />
      <span class="keyword">local</span> <span class="identifier">blueTeamScore</span> <span class="operator">=</span> <span class="number">0</span><br />
      <span class="keyword">local</span> <span class="identifier">lastPlayerTouched</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <span class="keyword">local</span> <span class="identifier">MessageBoard</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">MessageBoard</span><span class="operator">.</span><span class="property">SurfaceGui</span><span class="operator">.</span><span class="property">TextLabel</span><br />
      <span class="keyword">local</span> <span class="identifier">Lines</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">Lines</span><br />
      <span class="keyword">local</span> <span class="identifier">buzzer</span> <span class="operator">=</span> <span class="identifier">goalDetector</span><span class="operator">.</span><span class="property">BuzzSound</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">resetGame</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="identifier">player</span><span class="operator">:</span><span class="function">LoadCharacter</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">line</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Lines</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">line</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">fire</span> <span class="operator">=</span> <span class="identifier">line</span><span class="operator">.</span><span class="property">Fire</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">fire</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">leaderstats</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">score</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Score"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">score</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">score</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">=</span> <span class="identifier">NeutralTeam</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="identifier">MessageBoard</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"Game in progress..."</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="identifier">YellowScoreBlock</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"0"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">BlueScoreBlock</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"0"</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onBallTouched</span><span class="punctuation">(</span><span class="identifier">other</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">other</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">other</span><span class="operator">.</span><span class="property">Parent</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">==</span> <span class="identifier">BlueTeam</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">lastPlayerTouched</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">ball</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">Ball</span><br />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onBallTouched</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">goalScored</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">==</span> <span class="string">"Ball"</span> <span class="keyword">and</span> <span class="identifier">lastPlayerTouched</span> <span class="keyword">and</span> <span class="identifier">lastPlayerTouched</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">==</span> <span class="identifier">BlueTeam</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">lastPlayerTouched</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">score</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Score"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">score</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">score</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">score</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">+</span> <span class="number">1</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">blueTeamScore</span> <span class="operator">=</span> <span class="identifier">blueTeamScore</span> <span class="operator">+</span> <span class="number">1</span><br />
      <br />
      <Indent /><Indent /><Indent />
      <span class="identifier">BlueScoreBlock</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="identifier">blueTeamScore</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span><span class="number">0</span><span class="punctuation">,</span><span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">ballSpawn</span><span class="operator">.</span><span class="property">CFrame</span><br />
      <br />
      <Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">blueTeamScore</span> <span class="operator">==</span> <span class="number">2</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">MessageBoard</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"Blue team wins!"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">line</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Lines</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">line</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">flame</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Fire"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">flame</span><span class="operator">.</span><span class="property">Color</span> <span class="operator">=</span> <span class="builtin">Color3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0.054902</span><span class="punctuation">,</span> <span class="number">0.117647</span><span class="punctuation">,</span> <span class="number">1</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">flame</span><span class="operator">.</span><span class="property">Size</span> <span class="operator">=</span> <span class="number">500</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">flame</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">line</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">buzzer</span><span class="operator">:</span><span class="function">Play</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="builtin">wait</span><span class="punctuation">(</span><span class="number">5</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">resetGame</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="identifier">lastPlayerTouched</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">goalDetector</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">goalScored</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

// BlueGoalScript
const BlueGoalScript = () => {
  return (
    <CodeCard title={"Blue Goal Script"}>
      <span class="keyword">local</span> <span class="identifier">Teams</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Teams"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">Players</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"Players"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">YellowTeam</span> <span class="operator">=</span> <span class="identifier">Teams</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Yellow"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">NeutralTeam</span> <span class="operator">=</span> <span class="identifier">Teams</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Neutral"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">goalDetector</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">ReplicatedStorage</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"ReplicatedStorage"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">YellowScoreBlock</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">YellowScoreBlock</span><span class="operator">.</span><span class="property">SurfaceGui</span><span class="operator">.</span><span class="property">TextLabel</span><br />
      <span class="keyword">local</span> <span class="identifier">BlueScoreBlock</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">BlueScoreBlock</span><span class="operator">.</span><span class="property">SurfaceGui</span><span class="operator">.</span><span class="property">TextLabel</span><br />
      <span class="keyword">local</span> <span class="identifier">YellowTeamScore</span> <span class="operator">=</span> <span class="number">0</span><br />
      <span class="keyword">local</span> <span class="identifier">lastPlayerTouched</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <span class="keyword">local</span> <span class="identifier">MessageBoard</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">MessageBoard</span><span class="operator">.</span><span class="property">SurfaceGui</span><span class="operator">.</span><span class="property">TextLabel</span><br />
      <span class="keyword">local</span> <span class="identifier">Lines</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">Lines</span><br />
      <span class="keyword">local</span> <span class="identifier">buzzer</span> <span class="operator">=</span> <span class="identifier">goalDetector</span><span class="operator">.</span><span class="property">BuzzSound</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">resetGame</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="identifier">player</span><span class="operator">:</span><span class="function">LoadCharacter</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">line</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Lines</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">line</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">fire</span> <span class="operator">=</span> <span class="identifier">line</span><span class="operator">.</span><span class="property">Fire</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">fire</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">ball</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">Ball</span><br />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span><span class="number">0</span><span class="punctuation">,</span><span class="number">0</span><span class="punctuation">)</span><br />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">ballSpawn</span><span class="operator">.</span><span class="property">CFrame</span><br />
      <br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">player</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Players</span><span class="operator">:</span><span class="function">GetPlayers</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">leaderstats</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">score</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Score"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">score</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">score</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">=</span> <span class="identifier">NeutralTeam</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="identifier">MessageBoard</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"Game in progress..."</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="identifier">YellowScoreBlock</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"0"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">BlueScoreBlock</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"0"</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onBallTouched</span><span class="punctuation">(</span><span class="identifier">other</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">other</span><span class="operator">.</span><span class="property">Parent</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">other</span><span class="operator">.</span><span class="property">Parent</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">player</span> <span class="keyword">and</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">==</span> <span class="identifier">YellowTeam</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">lastPlayerTouched</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">local</span> <span class="identifier">ball</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">Ball</span><br />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onBallTouched</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">goalScored</span><span class="punctuation">(</span><span class="identifier">part</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">==</span> <span class="string">"Ball"</span> <span class="keyword">and</span> <span class="identifier">lastPlayerTouched</span> <span class="keyword">and</span> <span class="identifier">lastPlayerTouched</span><span class="operator">.</span><span class="property">Team</span> <span class="operator">==</span> <span class="identifier">YellowTeam</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">lastPlayerTouched</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">score</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Score"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">score</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">score</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">score</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">+</span> <span class="number">1</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">YellowTeamScore</span> <span class="operator">=</span> <span class="identifier">YellowTeamScore</span> <span class="operator">+</span> <span class="number">1</span><br />
      <br />
      <Indent /><Indent /><Indent />
      <span class="identifier">YellowScoreBlock</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="identifier">YellowTeamScore</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">Velocity</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span><span class="number">0</span><span class="punctuation">,</span><span class="number">0</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">ball</span><span class="operator">.</span><span class="property">CFrame</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">ballSpawn</span><span class="operator">.</span><span class="property">CFrame</span><br />
      <br />
      <Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">YellowTeamScore</span> <span class="operator">==</span> <span class="number">2</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">MessageBoard</span><span class="operator">.</span><span class="property">Text</span> <span class="operator">=</span> <span class="builtin">tostring</span><span class="punctuation">(</span><span class="string">"Yellow team wins!"</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">line</span> <span class="keyword">in</span> <span class="builtin">ipairs</span><span class="punctuation">(</span><span class="identifier">Lines</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">line</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="keyword">local</span> <span class="identifier">flame</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Fire"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">flame</span><span class="operator">.</span><span class="property">Color</span> <span class="operator">=</span> <span class="builtin">Color3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">,</span> <span class="number">1</span><span class="punctuation">,</span> <span class="number">0.0627451</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">flame</span><span class="operator">.</span><span class="property">Size</span> <span class="operator">=</span> <span class="number">500</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="identifier">flame</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">line</span><br />
      <Indent /><Indent /><Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">buzzer</span><span class="operator">:</span><span class="function">Play</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="builtin">wait</span><span class="punctuation">(</span><span class="number">5</span><span class="punctuation">)</span><br />
      <br />
      <Indent /><Indent /><Indent /><Indent />
      <span class="identifier">resetGame</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="identifier">lastPlayerTouched</span> <span class="operator">=</span> <span class="keyword">nil</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">goalDetector</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">goalScored</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

// ShiftToRunScript
const ShiftToRunScript = () => {
  return (
    <CodeCard title={"Shift To Run Script"}>
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">LocalPlayer</span><br />
      <span class="keyword">local</span> <span class="identifier">UserInputService</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">:</span><span class="function">GetService</span><span class="punctuation">(</span><span class="string">"UserInputService"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">normalSpeed</span> <span class="operator">=</span> <span class="number">16</span><br />
      <span class="keyword">local</span> <span class="identifier">runningSpeed</span> <span class="operator">=</span> <span class="number">32</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onCharacterAdded</span><span class="punctuation">(</span><span class="identifier">character</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">humanoid</span> <span class="operator">=</span> <span class="identifier">character</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Humanoid"</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">function</span> <span class="identifier">onKeyDown</span><span class="punctuation">(</span><span class="identifier">input</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="builtin">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="punctuation">.</span><span class="property">LeftShift</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="identifier">runningSpeed</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">function</span> <span class="identifier">onKeyUp</span><span class="punctuation">(</span><span class="identifier">input</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">input</span><span class="operator">.</span><span class="property">KeyCode</span> <span class="operator">==</span> <span class="builtin">Enum</span><span class="operator">.</span><span class="property">KeyCode</span><span class="punctuation">.</span><span class="property">LeftShift</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">humanoid</span><span class="operator">.</span><span class="property">WalkSpeed</span> <span class="operator">=</span> <span class="identifier">normalSpeed</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="identifier">UserInputService</span><span class="operator">.</span><span class="property">InputBegan</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onKeyDown</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">UserInputService</span><span class="operator">.</span><span class="property">InputEnded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onKeyUp</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">player</span><span class="operator">.</span><span class="property">CharacterAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onCharacterAdded</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">if</span> <span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span> <span class="keyword">then</span><br />
      <Indent />
      <span class="identifier">onCharacterAdded</span><span class="punctuation">(</span><span class="identifier">player</span><span class="operator">.</span><span class="property">Character</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}



const SoccerShowdown = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectVideo, setProjectVideo] = useState('');
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchVideoUrls = async (fileNames) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/videos`, { fileNames });
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching video URLs:', error.response?.data || error.message);
      return [];
    }
  };

  useEffect(() => {
    const fileNames = [
      'soccershowdown/SOCCERSHOWDOWN01.mp4', 
      'soccershowdown/SOCCERSHOWDOWN02.mp4', 
      'soccershowdown/SOCCERSHOWDOWN03.mp4',
      'soccershowdown/SOCCERSHOWDOWN04.mp4'
    ];

    fetchVideoUrls(fileNames).then((urls) => {
      if (urls && Array.isArray(urls)) {
        setVideoUrls(urls);

        // Dynamically create the tutorials array
        const dynamicTutorials = [
          ["Lay the turf and build the stadium", urls[0]?.url, <CopyBox title="Soccer Goal" text={"https://create.roblox.com/store/asset/72822908889088/SoccerGoal"} />, <CopyBox title="Bleacher" text={"https://create.roblox.com/store/asset/114055848019579/bleacher"} />, <CopyBox title="Stadium Light" text={"https://create.roblox.com/store/asset/76296813785049/StadiumLight"} />],
          ["Team System Scripts", urls[1]?.url, <GameSetupScript />, <YellowTeamPickerScript />, <BlueTeamPickerScript />],
          ["Where's the soccer ball?", urls[2]?.url, <SoccerBallScript />],
          ["He shoots, he scores!", urls[3]?.url, <CopyBox title="Buzz Sound I.D." text={"rbxassetid://5348162330"} />, <YellowGoalScript />, <CopyBox title="Buzz Sound I.D." text={"rbxassetid://5348162330"} />, <BlueGoalScript />, <ShiftToRunScript />]
        ];

        setTutorials(dynamicTutorials);
        setProjectName(dynamicTutorials[0][0]); // Set initial project name
        setProjectVideo(dynamicTutorials[0][1]); // Set initial project video
      }
      setLoading(false); // Set loading to false after fetching
    });
  }, []);

  const changeVideo = (tutorial, index) => {
    setProjectName(tutorial[0]);
    setProjectVideo(tutorial[1]);
    setTutorialIndex(index);
  };

  return (
    <div className='roblox-tutorial'>
      {
        loading === true ?
        <div className='loadcontainer'>
          <Loader />
        </div>
        :
        <div className='tutorial-structure'>
          <div className='tutorial-structure-left'>
            <Video title={projectName} vidfile={projectVideo} />
            
            {tutorials[tutorialIndex]?.length >= 3 &&
              tutorials[tutorialIndex]
                .slice(2) // Extracts all elements starting from index 2
                .map((tutorial, index) => (
                <div key={index}>{tutorial}</div> // Replace with your desired rendering logic
            ))}
          </div>

          <div className='tutorial-structure-right'>
            <div className='project-name'>
              <h3>Soccer Showdown</h3>
            </div>

            {tutorials.map((tutorial, index) => (
              <div
                key={index}
                onClick={() => changeVideo(tutorial, index)}
                className={`tutorial-card ${tutorialIndex === index ? 'selected' : ''}`}
              >
                <div className='tutorial-card-left'>
                  <p className='index'>{index + 1}.</p>
                </div>

                <div className='tutorial-card-right'>
                  <video
                    className='video-preview'
                    preload="metadata" // stops it from downloading full video
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source src={tutorial[1]} type="video/mp4" />
                    Failed to load video tag.
                  </video>

                  <p className='tutorial-name'>{tutorial[0]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

export default SoccerShowdown