import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';
import api from '../../../api/api';
import { toast } from 'react-toastify';
import "./attendance.css";

const Attendance = ({ setLoading, badge, path, setPath, project, setProject, setAttendance, status, setStatus, attendanceName, attendanceMessage, setAttendanceMessage, attendanceEmail, IDofStudent, _id, classID, tempID }) => {

    const closeForm = async () => {
        setPath("scratch")
        setProject("Apple Catch")
        setStatus(false)
        setAttendanceMessage("")
        setAttendance(false)
    }

    const submitAttendance = async () => {
        setAttendance(false)
        setLoading(true)

        try {
            const sendNotesResponse = await api.post(`${process.env.REACT_APP_API_URL}/send-private-lesson-notes`, {
                email: attendanceEmail,
                message: attendanceMessage,
                link: IDofStudent,
                teacherID: _id,
                classID: classID
            });

            const updateBadgeResponse = await api.post(`${process.env.REACT_APP_API_URL}/update-badge-status`, {
                studentID: tempID,
                projectType: path,
                projectName: project,
                status: status
            });

            const teacherStatus = await api.post(`${process.env.REACT_APP_API_URL}/add-to-status`, {
                teacherID: _id
            });

            toast.success("Notes sent!");
        } catch (err) {
            toast.error("Error, contact admin!")
        } finally {
            closeForm()
            setLoading(false)
        }
    };

    return (
        <div className='attendance__container'>

            <div className='attendance__form'>

                <div className='attendance__icon__container'>
                    <FaRegWindowClose className='close-button' onClick={() => closeForm()} size={30} />
                </div>

                <h1>{attendanceName}</h1>

                <div className='options__container' >
                    <p>
                        Learning Path:
                    </p>

                    <select value={path} onChange={(e) => setPath(e.target.value)} >
                        <option value={"scratch"} >Scratch</option>
                        <option value={"python"} >Python</option>
                        <option value={"roblox"} >Roblox & Lua</option>
                        <option value={"fullstack"} >Full Stack Development</option>
                    </select>
                </div>

                <select value={project} onChange={(e) => setProject(e.target.value)} className='project-selector'>
                    {
                        badge[path]?.map((course) => {
                            return (
                                <option value={course[0]} >{course[0]}</option>
                            )
                        })
                    }
                </select>

                <div className='options__container' >
                    <p>
                        Was the project completed?
                    </p>

                    <select className="wasCompleted" value={status} onChange={(e) => setStatus(e.target.value)} >
                        <option value={""} ></option>
                        <option value={true} >Yes</option>
                        <option value={false} >No</option>
                    </select>
                </div>

                <textarea className='textAreaNotes' placeholder='Write a message to the parents of your student!' value={attendanceMessage} onChange={(e) => setAttendanceMessage(e.target.value)} ></textarea>

                <button className='submitNotes' onClick={() => submitAttendance()} >Submit</button>
            </div>
        </div>
    )
}

export default Attendance