import React, { useEffect, useState } from 'react';
import Video from '../../components/video/video';
import CodeCard from '../../components/codecard/codecard';
import Indent from '../../components/indent/indent';
import api from "../../api/api";
import Loader from '../../components/loader/loader';


// LeverScript
const LeverScript = () => {
  return (
    <CodeCard title={"Lever Script"}>
      <span class="keyword">local</span> <span class="identifier">part</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">clickDetector</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">:</span><span class="function">FindFirstChildWhichIsA</span><span class="punctuation">(</span><span class="string">"ClickDetector"</span><span class="punctuation">)</span><br />
      <span class="keyword">local</span> <span class="identifier">defaultOrientation</span> <span class="operator">=</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onClick</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">part</span><span class="operator">.</span><span class="property">Orientation</span> <span class="operator">=</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Orientation</span> <span class="operator">+</span> <span class="builtin">Vector3</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="number">0</span><span class="punctuation">,</span> <span class="number">0</span><span class="punctuation">,</span> <span class="number">15</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">updateColor</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">part</span><span class="operator">.</span><span class="property">Orientation</span> <span class="operator">==</span> <span class="identifier">defaultOrientation</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">part</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright green"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">else</span><br />
      <Indent /><Indent />
      <span class="identifier">part</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright red"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">clickDetector</span><span class="operator">.</span><span class="property">MouseClick</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onClick</span><span class="punctuation">)</span><br />
      <br />
      <span class="keyword">while</span> <span class="builtin">wait</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent />
      <span class="identifier">updateColor</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}

// CheckLeversScript
const CheckLeversScript = () => {
  return (
    <CodeCard title={"Check Levers Script"}>
      <span class="keyword">local</span> <span class="identifier">leversFolder</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <span class="keyword">local</span> <span class="identifier">doorsFolder</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Workspace</span><span class="operator">.</span><span class="property">Doors</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">areAllLeversGreen</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">lever</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">leversFolder</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">lever</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BasePart"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">lever</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">~=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright green"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">return</span> <span class="boolean">false</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">return</span> <span class="boolean">true</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">isAnyLeverRed</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">lever</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">leversFolder</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">lever</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BasePart"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">lever</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">==</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright red"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">return</span> <span class="boolean">true</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">return</span> <span class="boolean">false</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">turnDoorsGreen</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">door</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">doorsFolder</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">door</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BasePart"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">door</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright green"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">door</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">turnDoorsRed</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">door</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">doorsFolder</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">door</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"BasePart"</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">door</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright red"</span><span class="punctuation">)</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">door</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">while</span> <span class="builtin">wait</span><span class="punctuation">(</span><span class="number">1</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">areAllLeversGreen</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">turnDoorsGreen</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">elseif</span> <span class="identifier">isAnyLeverRed</span><span class="punctuation">(</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">turnDoorsRed</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
    </CodeCard>
  )
}

// LeaderboardScript
const LeaderboardScript = () => {
  return (
    <CodeCard title={"Leaderboard Script"}>
      <span class="keyword">function</span> <span class="identifier">onPlayerAdded</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Folder"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"leaderstats"</span><br />
      <Indent />
      <span class="identifier">leaderstats</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">player</span><br />
      <br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">points</span> <span class="operator">=</span> <span class="builtin">Instance</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"IntValue"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="identifier">points</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">=</span> <span class="string">"Coins"</span><br />
      <Indent />
      <span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="number">0</span><br />
      <Indent />
      <span class="identifier">points</span><span class="operator">.</span><span class="property">Parent</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">.</span><span class="property">PlayerAdded</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onPlayerAdded</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

// CoinScript
const CoinScript = () => {
  return (
    <CodeCard title={"Coin Script"}>
      <span class="keyword">local</span> <span class="identifier">coin</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onTouched</span><span class="punctuation">(</span><span class="identifier">hit</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">hit</span><span class="operator">.</span><span class="property">Parent</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">points</span> <span class="operator">=</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">WaitForChild</span><span class="punctuation">(</span><span class="string">"Coins"</span><span class="punctuation">)</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">points</span> <span class="keyword">and</span> <span class="identifier">player</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">=</span> <span class="identifier">points</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">+</span> <span class="number">1</span><br />
      <Indent /><Indent />
      <span class="identifier">coin</span><span class="operator">:</span><span class="function">Destroy</span><span class="punctuation">(</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">coin</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onTouched</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}

// BigDoorScript
const BigDoorScript = () => {
  return (
    <CodeCard title={"Big Door Script"}>
      <span class="keyword">local</span> <span class="identifier">block</span> <span class="operator">=</span> <span class="builtin">script</span><span class="operator">.</span><span class="property">Parent</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">hasRequiredItems</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">leaderstats</span> <span class="operator">=</span> <span class="identifier">player</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"leaderstats"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">coins</span> <span class="operator">=</span> <span class="identifier">leaderstats</span> <span class="keyword">and</span> <span class="identifier">leaderstats</span><span class="operator">:</span><span class="function">FindFirstChild</span><span class="punctuation">(</span><span class="string">"Coins"</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">hasKey</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">coins</span> <span class="keyword">or</span> <span class="identifier">coins</span><span class="operator">.</span><span class="property">Value</span> <span class="operator">&lt;</span> <span class="number">8</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="keyword">return</span> <span class="boolean">false</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">for</span> <span class="punctuation">_</span><span class="punctuation">,</span> <span class="identifier">item</span> <span class="keyword">in</span> <span class="builtin">pairs</span><span class="punctuation">(</span><span class="identifier">player</span><span class="operator">.</span><span class="property">Backpack</span><span class="operator">:</span><span class="function">GetChildren</span><span class="punctuation">(</span><span class="punctuation">)</span><span class="punctuation">)</span> <span class="keyword">do</span><br />
      <Indent /><Indent />
      <span class="keyword">if</span> <span class="identifier">item</span><span class="operator">:</span><span class="function">IsA</span><span class="punctuation">(</span><span class="string">"Tool"</span><span class="punctuation">)</span> <span class="keyword">and</span> <span class="identifier">item</span><span class="operator">.</span><span class="property">Name</span> <span class="operator">==</span> <span class="string">"Key"</span> <span class="keyword">then</span><br />
      <Indent /><Indent /><Indent />
      <span class="identifier">hasKey</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent /><Indent /><Indent />
      <span class="keyword">break</span><br />
      <Indent /><Indent />
      <span class="keyword">end</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">return</span> <span class="identifier">hasKey</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="keyword">function</span> <span class="identifier">onTouched</span><span class="punctuation">(</span><span class="identifier">hit</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">local</span> <span class="identifier">player</span> <span class="operator">=</span> <span class="builtin">game</span><span class="operator">.</span><span class="property">Players</span><span class="operator">:</span><span class="function">GetPlayerFromCharacter</span><span class="punctuation">(</span><span class="identifier">hit</span><span class="operator">.</span><span class="property">Parent</span><span class="punctuation">)</span><br />
      <Indent />
      <span class="keyword">if</span> <span class="keyword">not</span> <span class="identifier">player</span> <span class="keyword">then</span> <span class="keyword">return</span> <span class="keyword">end</span><br />
      <br />
      <Indent />
      <span class="keyword">if</span> <span class="identifier">hasRequiredItems</span><span class="punctuation">(</span><span class="identifier">player</span><span class="punctuation">)</span> <span class="keyword">then</span><br />
      <Indent /><Indent />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright green"</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">false</span><br />
      <Indent />
      <span class="keyword">else</span><br />
      <Indent /><Indent />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">BrickColor</span> <span class="operator">=</span> <span class="builtin">BrickColor</span><span class="operator">.</span><span class="function">new</span><span class="punctuation">(</span><span class="string">"Bright red"</span><span class="punctuation">)</span><br />
      <Indent /><Indent />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">CanCollide</span> <span class="operator">=</span> <span class="boolean">true</span><br />
      <Indent />
      <span class="keyword">end</span><br />
      <span class="keyword">end</span><br />
      <br />
      <span class="identifier">block</span><span class="operator">.</span><span class="property">Touched</span><span class="operator">:</span><span class="function">Connect</span><span class="punctuation">(</span><span class="identifier">onTouched</span><span class="punctuation">)</span><br />
    </CodeCard>
  )
}



const CastleConundrum = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectVideo, setProjectVideo] = useState('');
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchVideoUrls = async (fileNames) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/videos`, { fileNames });
      return response.data; // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching video URLs:', error.response?.data || error.message);
      return [];
    }
  };

  useEffect(() => {
    const fileNames = [
      'castleconundrum/CASTLECONUNDRUM01.mp4', 
      'castleconundrum/CASTLECONUNDRUM02.mp4', 
      'castleconundrum/CASTLECONUNDRUM03.mp4',
      'castleconundrum/CASTLECONUNDRUM04.mp4',
      'castleconundrum/CASTLECONUNDRUM05.mp4',
      'castleconundrum/CASTLECONUNDRUM06.mp4',
    ];

    fetchVideoUrls(fileNames).then((urls) => {
      if (urls && Array.isArray(urls)) {
        setVideoUrls(urls);

        // Dynamically create the tutorials array
        const dynamicTutorials = [
          ["Exploring the Castle Grounds", urls[0]?.url, <LeverScript />],
          ["Finishing the Lever Trap", urls[1]?.url, <CheckLeversScript />],
          ["Hide the Secret Key", urls[2]?.url],
          ["Leaderboars, Coins, and Code", urls[3]?.url, <LeaderboardScript />, <CoinScript />],
          ["The Big Door of Doom", urls[4]?.url, <BigDoorScript />],
          ["Castle Boarders!... there is no escape", urls[5]?.url]
        ];

        setTutorials(dynamicTutorials);
        setProjectName(dynamicTutorials[0][0]); // Set initial project name
        setProjectVideo(dynamicTutorials[0][1]); // Set initial project video
      }
      setLoading(false); // Set loading to false after fetching
    });
  }, []);

  const changeVideo = (tutorial, index) => {
    setProjectName(tutorial[0]);
    setProjectVideo(tutorial[1]);
    setTutorialIndex(index);
  };

  return (
    <div className='roblox-tutorial'>
      {
        loading === true ?
        <div className='loadcontainer'>
          <Loader />
        </div>
        :
        <div className='tutorial-structure'>
          <div className='tutorial-structure-left'>
            <Video title={projectName} vidfile={projectVideo} />
            
            {tutorials[tutorialIndex]?.length >= 3 &&
              tutorials[tutorialIndex]
                .slice(2) // Extracts all elements starting from index 2
                .map((tutorial, index) => (
                <div key={index}>{tutorial}</div> // Replace with your desired rendering logic
            ))}
          </div>

          <div className='tutorial-structure-right'>
            <div className='project-name'>
              <h3>Castle Conundrum</h3>
            </div>

            {tutorials.map((tutorial, index) => (
              <div
                key={index}
                onClick={() => changeVideo(tutorial, index)}
                className={`tutorial-card ${tutorialIndex === index ? 'selected' : ''}`}
              >
                <div className='tutorial-card-left'>
                  <p className='index'>{index + 1}.</p>
                </div>

                <div className='tutorial-card-right'>
                  <video
                    className='video-preview'
                    preload="metadata" // stops it from downloading full video
                    disablePictureInPicture
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source src={tutorial[1]} type="video/mp4" />
                    Failed to load video tag.
                  </video>

                  <p className='tutorial-name'>{tutorial[0]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}

export default CastleConundrum